import { colors } from 'shared/constants/theme';

export const wrapperStyles = {
  borderBottom: '1px solid #ECECEC',
  borderRadius: 0,
};

export const containerStyles = {
  padding: 3,
  paddingBottom: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const priceStyles = {
  fontSize: {
    xl: 16,
    lg: 16,
    md: 16,
    sm: 16,
    xs: 16,
  },
  fontWeight: 500,
};

export const priceContainerStyles = {
  display: 'flex',
  columnGap: 1,
  alignItems: 'baseline',
};

export const filterStyles = {
  display: 'flex',
  columnGap: { xl: 2, lg: 1.5, md: 1, sm: 1, xs: 1 },
  marginTop: 2,
};

export const errorIconStyles = {
  color: colors.grey,
};

export const chartContainerStyles = {
  padding: 3,
  height: '100%',
};

export const dateStyles = (showBorderBottom: boolean) => ({
  borderBottom: showBorderBottom ? `2px solid ${colors.lightGreen}` : '',
  fontSize: {
    xl: 18,
    lg: 18,
    md: 14,
    sm: 16,
    xs: 16,
  },
  '&:hover': {
    cursor: 'pointer',
  },
});
