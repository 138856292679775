import { Controller } from 'react-hook-form';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { useMemo, useRef, useLayoutEffect, useState } from 'react';
import { colors } from 'shared/constants/theme';
import { MarkIcon } from 'components/Icons/MarkLogo';
import {
  asteriskStyles,
  containerStyles,
  errorTextStyles,
  labelContainerStyles,
  labelStyles,
  markIconStyles,
  menuItemStyles,
  muSelectStyles,
  paperStyles,
} from './styles';

export const ControlledSelect = (props: any) => {
  const {
    control,
    disabled,
    name,
    label,
    error,
    required,
    options,
    defaultValue,
    placeholder,
    dark = false,
    sx = {},
  } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [menuWidth, setMenuWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setMenuWidth(wrapperRef.current.offsetWidth);
    }
  }, []);

  const handleRenderValue = (selected: any) => {
    if (!selected)
      return (
        <span style={{ color: dark ? colors.grey : '#aaa' }}>
          {placeholder}
        </span>
      );

    return options.find((option: any) => option.value === selected)?.name;
  };

  const borderStyles = useMemo(() => {
    if (error[name]) return '1px solid #ff4242 !important';
    if (dark) return `1px solid ${colors.grey} !important`;
  }, [error, error[name], dark]);

  return (
    <Box sx={containerStyles} ref={wrapperRef}>
      {label || required ? (
        <Box sx={labelContainerStyles}>
          {label ? (
            <Typography sx={labelStyles(dark)}>{label}</Typography>
          ) : null}
          {required && <Typography sx={asteriskStyles}>*</Typography>}
        </Box>
      ) : null}
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <MuiSelect
              disabled={disabled}
              fullWidth
              displayEmpty
              renderValue={handleRenderValue}
              MenuProps={{
                PaperProps: {
                  sx: paperStyles(menuWidth, dark),
                },
              }}
              sx={{
                ...muSelectStyles(borderStyles, dark),
                ...sx,
              }}
              {...field}
            >
              {options.map((option: any, index: number) => {
                const isSelected = field.value === option.value;

                return (
                  <MenuItem
                    value={option.value}
                    key={`select-${index}`}
                    selected={isSelected}
                    sx={menuItemStyles(dark)}
                  >
                    {option.name}
                    {dark && isSelected && (
                      <Box component="span" sx={markIconStyles}>
                        <MarkIcon />
                      </Box>
                    )}
                  </MenuItem>
                );
              })}
            </MuiSelect>
            <FormHelperText sx={errorTextStyles}>
              {!!error[name] ? error[name]?.message : ''}
            </FormHelperText>
          </>
        )}
      />
    </Box>
  );
};
