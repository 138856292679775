import { colors } from 'shared/constants/theme';
import Elipse from 'assets/images/elipse.png';

type GetDynamicStyles = {
  colored: boolean;
  small: boolean;
};

export const containerStyles = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const dateStyles = {
  fontSize: {
    xl: '14px',
    lg: '14px',
    md: '14px',
    sm: '12px',
    xs: '12px',
  },
  color: colors.grey,
};

export const valueStyles = {
  fontSize: {
    xl: '32px',
    lg: '32px',
    md: '25px',
    sm: '35px',
    xs: '28px',
  },
};

export const currentDateStyles = {
  fontSize: {
    xl: '15px',
    lg: '15px',
    md: '10px',
    sm: '15px',
    xs: '12px',
  },
};

export const getDynamicStyles = ({ colored, small }: GetDynamicStyles) => {
  const styles: any = {
    height: small ? 'auto' : '175px',
  };

  if (colored) {
    styles.background = colors.lightGreen;
    styles.backgroundImage = `url(${Elipse})`;
    styles.backgroundSize = '250px 250px';
    styles.backgroundRepeat = 'no-repeat';
    styles.backgroundPosition = 'right 0 top -50px';
    styles.border = 'none';
    styles.zIndex = 5;
  }

  return styles;
};
