import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomersDetails } from 'components/CustomerDetails';
import { Upfront } from 'components/CustomerDetails/types';
import { routesMapping } from 'routes/mappings';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { coins } from 'shared/utils/currency';
import UsersService from 'shared/services/users.service';
import ContractsService from 'shared/services/contracts.service';

export const AffiliateCustomerDetails = () => {
  const { customerId } = useParams();

  const [customer, setCustomer] = useState<any>();
  const [upfront, setUpfront] = useState<Upfront>();
  const [owner, setOwner] = useState<any>();
  const [selectedCurrency, setSelectedCurrency] = useState(coins[0]);

  const fetchUpfront = async () => {
    const data = await ContractsService.getAffiliateCustomerUpfront(
      customerId!,
    );
    setUpfront(data);
  };

  const fetchCustomer = async () => {
    const user = await UsersService.getAffiliateCustomer(customerId!);
    setCustomer(user);
  };

  const fetchOwner = async () => {
    const user = await UsersService.getUser(customer.ownerId);
    setOwner(user);
  };

  useEffect(() => {
    if (!customerId) return;

    fetchUpfront();
    fetchCustomer();
    if (customer) fetchOwner();
  }, [customerId]);

  return (
    <CustomersDetails
      backNavigation={routesMapping.affiliateCustomers}
      customer={customer}
      owner={owner}
      upfront={upfront}
      setSelectedCurrency={setSelectedCurrency}
      selectedCurrency={selectedCurrency}
      coins={coins}
      role={UserRole.AFFILIATE}
    />
  );
};
