import { Box, Skeleton, Typography } from '@mui/material';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { InfoCard } from 'components/Cards/InfoCard';
import { backgroundStyles } from '../styles';
import {
  totalEarningsStyles,
  cardContentWrapperStyles,
  totalEarningsValueStyles,
} from './styles';

type Props = {
  selectedCurrency: any;
  btcSinceBeginning?: any;
};

export const TotalUpfront = (props: Props) => {
  const { selectedCurrency, btcSinceBeginning } = props;

  return (
    <InfoCard
      headerColor="white"
      infoIconColor="white"
      showDivider={false}
      showTime={false}
      fullHeight
      styles={backgroundStyles}
      content={
        <Box sx={cardContentWrapperStyles}>
          <Box>
            <Typography sx={totalEarningsStyles} variant="h5">
              Total {selectedCurrency.coinAbb} earnings
            </Typography>
            <Typography sx={totalEarningsValueStyles}>
              {typeof btcSinceBeginning === 'number' ? (
                <>
                  {formatNumberToLocale(btcSinceBeginning, 6)}{' '}
                  {selectedCurrency.coinAbb}
                </>
              ) : (
                <Skeleton variant="rectangular" height={56} width={300} />
              )}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
