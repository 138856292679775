import HttpService from './http.service';
class SimualationService {
  endpoint: string = 'simulation';

  getSimulations = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getSimulationsAffiliate = async (params: any) =>
    await HttpService.get(`${this.endpoint}/affiliate`, params);

  generateSimulation = async (params: any) =>
    await HttpService.post(`${this.endpoint}/generate`, params);

  createSimulation = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateSimulation = async (hostingId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${hostingId}`, payload);

  deleteSimulation = async (hostingId: string) =>
    await HttpService.delete(`${this.endpoint}/${hostingId}`);

  getDollarYieldSimulations = async () =>
    await HttpService.get(`${this.endpoint}/dollar-yield`);
}

export default new SimualationService();
