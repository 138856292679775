import { colors } from 'shared/constants/theme';

export const containerStyles = {
  marginBottom: '10px',
};

export const labelStyles = (dark: any) => {
  return {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '15px',
    color: dark ? colors.lightGrey : colors.black,
    marginTop: '10px',
    marginBottom: '10px',
  };
};

export const inputsContainer = {
  display: 'flex',
  alignItems: 'center',
  '& > :last-child': {
    flex: 1,
    marginBottom: '2px',
    '& fieldset': {
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
    },
  },
};

export const selectContainer = {
  width: '24%',
};

export const selectStyles = {
  margin: 0,
  width: '100%',
  marginBottom: 0,
  borderRadius: '8px',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: 'unset',
  border: 'none',
};

export const textFieldStyles = {
  margin: 0,
};

export const errorLineStyles = {
  fontFamily: 'Montserrat !important',
  fontStyle: 'normal !important',
  fontWeight: '450 !important',
  fontSize: '10px !important',
  lineHeight: '20px !important',
  color: colors.errorRed,
  marginTop: '3px',
  marginRight: '14px',
  marginBottom: 0,
  marginLeft: '14px',
};

export const flagStyles = { width: 20, height: 14, borderRadius: 2 };
