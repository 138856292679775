import moment from 'moment/moment';
import { NorthEast } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { colors } from 'shared/constants/theme';
import {
  dateStyles,
  valueStyles,
  containerStyles,
  getDynamicStyles,
  currentDateStyles,
} from './styles';

export const StatisticsCard = (props: any) => {
  const {
    value,
    title,
    currency,
    small = false,
    colored = false,
    date,
  } = props;

  return (
    <InfoCard
      styles={getDynamicStyles({ small, colored })}
      showDivider={false}
      showTime={false}
      subtitle={title}
      headerColor={colored && colors.white}
      title=""
      content={
        <Box sx={containerStyles}>
          {value !== null ? (
            <>
              <Box>
                {date && <Typography sx={dateStyles}>{date}</Typography>}
                <Typography
                  sx={valueStyles}
                  color={colored ? 'white' : 'secondary'}
                  variant="h3"
                  fontWeight={500}
                >
                  {`${value?.toLocaleString('en-EN')} ${currency}`}
                </Typography>
              </Box>
              {!small && (
                <Typography
                  sx={currentDateStyles}
                  color={colored ? 'white' : colors.grey}
                  alignItems="center"
                  display="flex"
                >
                  {moment().format('MMMM yyyy')}
                  <NorthEast />
                </Typography>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" height={80} width="100%" />
          )}
        </Box>
      }
    />
  );
};
