export const MarkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96289 12.4776L10.0328 15.5475L10.013 15.5276L16.9391 8.60156"
        stroke="#FBFBFB"
        strokeWidth="2.12411"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
