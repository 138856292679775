import { colors } from 'shared/constants/theme';

export const headingWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',
};

export const headingStyles = {
  color: colors.white,
};
