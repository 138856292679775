import { Box, Skeleton, Typography } from '@mui/material';
import { Card } from 'components/Cards/Card';
import { subtitleStyles } from 'components/Cards/styles';
import { HearstIcon } from 'components/Icons/HearstIcon';
import { colors } from 'shared/constants/theme';
import { formatNumberToLocale, formatUsd } from 'shared/utils/formatter';

type Props = {
  totalBTC?: number;
  totalUSD?: number;
  coinAbb?: string;
  isAffiliate?: boolean;
};
export const TotalEarnings = ({
  totalBTC,
  totalUSD,
  coinAbb,
  isAffiliate,
}: Props) => {
  return (
    <Card
      styles={{
        background: colors.lightGreen,
        border: 'unset',
        padding: '20px',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          sx={{
            ...subtitleStyles,
            color: 'white',
            marginBottom: '50px',
          }}
          variant="h2"
        >
          Total earnings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '14px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'end', gap: '40px' }}>
            <Box>
              {isAffiliate ? <></> : <Box>{coinAbb}</Box>}
              {typeof totalBTC === 'number' ? (
                <Box
                  sx={{
                    fontSize: {
                      xs: '16px',
                      sm: '16px',
                      md: '42px',
                      lg: '42px',
                    },
                    fontWeight: '600',
                  }}
                >
                  {formatNumberToLocale(totalBTC, coinAbb === 'KAS' ? 3 : 6)}{' '}
                  {coinAbb}
                </Box>
              ) : (
                <Skeleton height={63} width={100} />
              )}
            </Box>
            {isAffiliate ? (
              <></>
            ) : (
              <Box>
                <Box>USD</Box>
                {typeof totalUSD === 'number' ? (
                  <Box
                    sx={{
                      fontSize: {
                        xs: '16px',
                        sm: '16px',
                        md: '42px',
                        lg: '42px',
                      },
                      fontWeight: '600',
                    }}
                  >
                    ${formatUsd(totalUSD)} USD
                  </Box>
                ) : (
                  <Skeleton height={63} width={100} />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginRight: '10%' }}>
        <HearstIcon width="84" height="92" />
      </Box>
    </Card>
  );
};
