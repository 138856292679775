import { Box, Grid, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { MinerChart } from 'components/Charts/MinerChart';
import { DistributionPerCountry } from 'components/DistributionPerCountry';
import { WorldMap } from 'components/WorldMap';
import { SectionHeading } from 'components/SectionHeading';
import { itemHeaderStyles } from 'pages/Customer/Operation/Miners/styles';
import countryCodes from 'shared/constants/country-codes.json';
import { CircleFlag } from 'react-circle-flags';

export const Miners = (props: any) => {
  const { distributionData, machinePerTypeData, worldData } = props;

  const location = (country: any) => {
    switch (country) {
      case 'AE':
        return 'UAE';
      default:
        return countryCodes.countries.find((c: any) => c.alpha2 === country)
          ?.country;
    }
  };

  return (
    <>
      <SectionHeading
        sx={{
          marginTop: 8,
        }}
        name="Your miners"
      />
      <Grid
        marginTop={2}
        container
        rowSpacing={2}
        columnSpacing={2}
        sx={{ paddingLeft: '13px' }}
      >
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              xl: 'row',
              lg: 'row',
              md: 'column',
            },
            columnGap: { xl: '20px', lg: '20px' },
            rowGap: { xs: '20px', sm: '20px', md: '20px' },
          }}
        >
          <InfoCard
            showDivider={false}
            showTime={false}
            fullHeight
            title=""
            styles={{
              width: {
                xs: '100%',
                sm: '100%',
                xl: '70%',
                lg: '70%',
                md: '100%',
              },
              height: { xl: '650px', lg: '650px' },
            }}
            hasAdditionalSpace={false}
            content={
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginTop: {
                    xs: '20px',
                    sm: '20px',
                    xl: '0px',
                    lg: '0px',
                    md: '20px',
                  },
                }}
              >
                <Typography
                  sx={{
                    ...itemHeaderStyles,
                    marginLeft: '20px',
                    marginBottom: '30px',
                  }}
                >
                  Machine per Country
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      xl: 'row',
                      lg: 'row',
                      md: 'row',
                    },
                    marginBottom: '20px',
                    marginLeft: '20px',
                    columnGap: '40px',
                    rowGap: '20px',
                  }}
                >
                  {worldData.map((country: any) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircleFlag
                        countryCode={country.location.toLowerCase()}
                        height="30"
                      />
                      <Typography
                        sx={{
                          color: '#131613',
                          fontSize: '16px',
                          fontWeight: '600',
                          marginLeft: '10px',
                        }}
                      >
                        {location(country.location)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'secondary.main',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                      >
                        -{country.machineNumber}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <WorldMap countries={worldData} />
              </Grid>
            }
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            rowSpacing={4}
            columnSpacing={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: {
                xl: '20px',
                lg: '20px',
                md: '20px',
                xs: '20px',
                sm: '20px',
              },
            }}
          >
            <InfoCard
              showDivider={false}
              showTime={false}
              fullHeight
              title=""
              styles={{
                width: '100%',
                height: { xl: '315px', lg: '315px' },
                overflowY: 'auto',
              }}
              hasAdditionalSpace={false}
              content={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '30px',
                    paddingBottom: '30px',
                  }}
                >
                  <Typography sx={itemHeaderStyles}>
                    Distribution per Country
                  </Typography>
                  <DistributionPerCountry
                    items={distributionData}
                    getCountry={location}
                  />
                </Box>
              }
            />
            <InfoCard
              showDivider={false}
              showTime={false}
              fullHeight
              title=""
              styles={{
                width: '100%',
                height: { xl: '315px', lg: '315px' },
                overflowY: 'auto',
              }}
              hasAdditionalSpace={false}
              content={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '30px',
                    paddingBottom: '30px',
                  }}
                >
                  <Typography sx={itemHeaderStyles}>Miner per Type</Typography>
                  <MinerChart items={machinePerTypeData} />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
