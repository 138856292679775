import { useState } from 'react';
import AdminContractsTable from 'components/Tables/AdminContractsTable';

const Contracts = () => {
  const [userDeleted, setUserDeleted] = useState(false);

  return (
    <AdminContractsTable
      allContracts
      userDeleted={userDeleted}
      setUserDeleted={setUserDeleted}
    />
  );
};

export default Contracts;
