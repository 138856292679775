export const dollarYieldDescriptions = {
  Bitcoin:
    'The highest-value crypto asset, historically delivering strong dollar returns and long-term price growth.',
  BitcoinCash:
    'A lower-cost alternative to BTC, offering steady demand and mining rewards in USD terms.',
  Litecoin:
    'An affordable crypto with consistent trading volume and strong USD-convertible mining yield.',
  Kaspa:
    'A high-potential emerging coin with strong recent performance and attractive dollar-denominated returns.',
  Nervos:
    'A low-entry crypto asset with upside potential and regular USD-yield mining payouts.',
  Alephium:
    'An early-stage coin offering promising returns, ideal for high-upside, USD-driven mining strategies.',
};
