import { colors } from 'shared/constants/theme';

export const passwordInputContainer = {
  marginTop: '6px',
  marginBottom: '42px',
};

export const middleLineStyles = {
  backgroundColor: colors.darkGrey,
  width: '100%',
  border: 'unset',
  height: '0.5px',
  marginTop: '30px',
  marginBottom: '14px',
};

export const formContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& input': {
    padding: '12.5px 14px',
  },
  '& .MuiSelect-outlined': {
    padding: '12.5px 14px',
  },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${colors.grey} !important`,
  },
};

export const usernameSectionStyles = {
  display: 'flex',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'row',
    xs: 'column',
  },
  columnGap: '16px',
  alignItems: 'start',
  marginBottom: '10px',
};

export const passwordInfoTextStyles = {
  marginTop: '4px',
  fontSize: '12px',
  fontWeight: 300,
  color: colors.grey,
};

export const hideSelectBorderStyles = {
  border: 'none',
};

export const companyInputStyles = {
  marginBottom: '20px',
};
