import { colors } from 'shared/constants/theme';

export const initialStyles = {
  background:
    'linear-gradient(117deg, rgba(4,40,20,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(4,40,20,1) 100%)',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  padding: {
    sm: 3,
    xs: 3,
    md: '60px 32px 32px',
    lg: '60px 32px 32px',
    xl: '60px 32px 32px',
  },
};

export const layoutContainer = {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: colors.backgroundGrey,
};

export const childerContainerStyles = {
  padding: {
    sm: 3,
    xs: 3,
    md: '40px 32px',
    lg: '40px 32px',
    xl: '40px 32px',
  },
  paddingX: 5,
};

export const authLayoutContainerStyles = {
  bgcolor: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '80px',
  overflow: 'hidden',
  minWidth: '100%',
  minHeight: '100%',
  overflowY: 'hidden',
  borderRadius: '0px',
  padding: '85px 70px',
};

export const authLayoutContentStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 10,
};

export const campaignLayoutContainer = {
  background:
    'linear-gradient(117deg, rgba(4,40,20,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(4,40,20,1) 100%)',
  display: 'flex',
  gap: '40px',
  overflow: 'hidden',
  minWidth: '100%',
  minHeight: '100%',
  overflowY: 'hidden',
  borderRadius: '0px',
  padding: '40px',
};

export const campaignLayoutContentStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '26px',
};

export const imageContainerStyles = {
  display: {
    xs: 'none',
    lg: 'flex',
  },
  flex: {
    lg: 1,
    xl: 'unset',
  },
  '& img': {
    width: '100%',
    objectFit: 'contain',
  },
};
