import { Box, Button, Typography } from '@mui/material';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { useState } from 'react';
import {
  cardContainer,
  cardTitleContainer,
  countryNameStyles,
  cardDescription,
  innerCardStyles,
  sectionContainer,
  sectionTitle,
  sectionValue,
  bookButtonStyles,
  flagStyles,
} from '../styles';
import { DatePickerModal } from 'components/CampaignProspects/DatePickerModal';
import Flag from 'react-world-flags';

interface DateRange {
  start: string;
  end: string;
}

interface BtcReserveCardProps {
  btcReserveItem: any;
  dates: DateRange[];
}

export const BtcReserveCard: React.FC<BtcReserveCardProps> = (props: any) => {
  const { btcReserveItem, dates } = props;
  const { country, countryCode, spending, currentPrice, profit } =
    btcReserveItem;

  const [open, setOpen] = useState(false);

  return (
    <Box sx={cardContainer}>
      <Box sx={cardTitleContainer}>
        <Flag code={countryCode} style={flagStyles} />
        <Typography sx={countryNameStyles}>{country}</Typography>
      </Box>
      <Box sx={innerCardStyles}>
        <Box sx={sectionContainer}>
          <Typography sx={sectionTitle}>Mining Cost:</Typography>
          <Typography sx={sectionValue}>
            ${formatNumberToLocale(spending, 0)} per BTC
          </Typography>
        </Box>
        <Box sx={sectionContainer}>
          <Typography sx={sectionTitle}>Market Price:</Typography>
          <Typography sx={sectionValue}>
            ${formatNumberToLocale(currentPrice, 0)} per BTC
          </Typography>
        </Box>
        <Typography sx={sectionValue}>
          ${formatNumberToLocale(profit, 0)} saved per BTC
        </Typography>
      </Box>
      <Typography sx={cardDescription}>
        By mining BTC in {country}, you save thousands per Bitcoin compared to
        buying at market rates. This translates to better long-term reserve
        value as BTC prices increase.
      </Typography>
      <Box>
        <Button sx={bookButtonStyles} onClick={() => setOpen(!open)}>
          Book a Meeting
        </Button>
        <DatePickerModal
          open={open}
          setOpen={setOpen}
          dates={dates}
          subject={country}
        />
      </Box>
    </Box>
  );
};
