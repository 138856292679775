export enum UserRole {
  CUSTOMER = 'Customer',
  ADMIN = 'Admin',
  SUBACCOUNT = 'SubAccount',
  AFFILIATE_SUBACCOUNT = 'AffiliateSubAccount',
  SUPPLIER = 'Supplier',
  HARDWARE_SUPPLIER = 'HardwareSupplier',
  AFFILIATE = 'Affiliate',
  CAMPAIGN_PROSPECT = 'CampaignProspect',
}
