import { Box } from '@mui/material';
import { HearstLogo } from 'components/Icons/HearstLogo';
import { AccountInfo } from 'pages/SignUp/Steps/AccountInfo';
import { AccountType } from 'pages/SignUp/Steps/AccountType';
import { CheckEmail } from 'pages/SignUp/Steps/CheckEmail';
import { CreatePassword } from 'pages/SignUp/Steps/CreatePassword';
import { StartKyc } from 'pages/SignUp/Steps/StartKyc';
import {
  AccountInfoState,
  AccountTypeState,
  SignupStepsList,
} from 'pages/SignUp/types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { styles } from './styles';

const steps: SignupStepsList[] = [
  SignupStepsList.accountType,
  SignupStepsList.accountInfo,
  SignupStepsList.checkEmail,
  SignupStepsList.createPassword,
  SignupStepsList.startKyc,
];

const SignUpSteps = () => {
  const [step, setStep] = useState<SignupStepsList>(steps[0]);
  const [completedSteps, setCompletedSteps] = useState<SignupStepsList[]>([]);

  const [accountType, setAccountType] = useState<AccountTypeState>();
  const [accountInfo, setAccountInfo] = useState<AccountInfoState>();
  const [emailToken, setEmailToken] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();

  const nextStep = () => {
    setCompletedSteps((prev) => [...prev, step]);
    setStep(steps[steps.indexOf(step) + 1]);
  };

  const prevStep = () => {
    setCompletedSteps((prev) => prev.slice(prev.indexOf(step), 1));
    setStep(steps[steps.indexOf(step) - 1]);
  };

  return (
    <Box sx={styles.stepsContainer}>
      <Box sx={styles.header}>
        <Link to={routesMapping.login}>
          <HearstLogo />
        </Link>
        <Box sx={styles.stepIndicatorContainer}>
          {Object.values(steps).map((s) => (
            <Box
              key={s}
              sx={[
                styles.stepIndicator,
                completedSteps.includes(s) || s === step
                  ? styles.stepIndicatorCompleted
                  : {},
              ]}
            ></Box>
          ))}
        </Box>
      </Box>

      <Box sx={styles.bodyContainer}>
        {step === SignupStepsList.accountType && (
          <AccountType setAccountType={setAccountType} nextStep={nextStep} />
        )}
        {step === SignupStepsList.accountInfo && (
          <AccountInfo
            accountType={accountType}
            nextStep={nextStep}
            prevStep={prevStep}
            setAccountInfo={setAccountInfo}
          />
        )}
        {step === SignupStepsList.checkEmail && accountInfo?.email && (
          <CheckEmail
            email={accountInfo.email}
            setEmailToken={setEmailToken}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        {step === SignupStepsList.createPassword &&
          emailToken &&
          accountInfo?.email && (
            <CreatePassword
              email={accountInfo.email}
              emailToken={emailToken}
              nextStep={nextStep}
              prevStep={prevStep}
              setAccessToken={setAccessToken}
            />
          )}
        {step === SignupStepsList.startKyc && accessToken && (
          <StartKyc accountType={accountType} accessToken={accessToken} />
        )}
      </Box>
    </Box>
  );
};

export default SignUpSteps;
