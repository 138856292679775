import HttpService from './http.service';
class UsersService {
  endpoint: string = 'users';

  me = async () => await HttpService.get(`${this.endpoint}/me`);

  getUsers = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getUser = async (id: any) => await HttpService.get(`${this.endpoint}/${id}`);

  getSubAccounts = async (params: any) =>
    await HttpService.get(`${this.endpoint}/sub-account`, params);

  getAllSubAccounts = async (params: any) =>
    await HttpService.get(`${this.endpoint}/sub-accounts`, params);

  getSuppliers = async (params: any) =>
    await HttpService.get(`${this.endpoint}/suppliers`, params);

  getAllSuppliers = async (params: any = {}) =>
    await HttpService.get(`${this.endpoint}/all-suppliers`, params);

  getCustomers = async () =>
    await HttpService.get(`${this.endpoint}/customers`);

  getCustomersWithoutAffiliate = async () =>
    await HttpService.get(`${this.endpoint}/customer-without-affiliate`);

  getAffiliateCustomers = async (params: any) =>
    await HttpService.get(`${this.endpoint}/customers-affiliate`, params);

  getAffiliateSubAccounts = async (params: any) =>
    await HttpService.get(`${this.endpoint}/affiliate-sub-account`, params);

  getAffiliateCustomer = async (userId: string) =>
    await HttpService.get(`${this.endpoint}/customer-affiliate/${userId}`);

  getAllAffiliates = async () =>
    await HttpService.get(`${this.endpoint}/affiliate/all`);

  getCustomerStatistic = async (params: any) =>
    await HttpService.get(`${this.endpoint}/customers-statistic`, params);

  createUser = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  createHardwareSupplier = async (payload: any) =>
    await HttpService.post(
      `${this.endpoint}/create-hardware-supplier`,
      payload,
    );

  updateUser = async (userId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${userId}`, payload);

  updateCurrentUser = async (payload: any) =>
    await HttpService.put(`${this.endpoint}`, payload);

  deleteUser = async (userId: string) =>
    await HttpService.delete(`${this.endpoint}/${userId}`);

  updateAffiliateSubAcc = async (userId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/affiliate/${userId}`, payload);

  deleteAffiliateSubAcc = async (userId: string) =>
    await HttpService.delete(`${this.endpoint}/affiliate/${userId}`);

  getUserCalendarSlots = async () =>
    await HttpService.get(`${this.endpoint}/calendar/slots`);

  setUserCalendarSlot = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/calendar/book`, payload);
}

export default new UsersService();
