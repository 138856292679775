export const headingStyles = {
  marginTop: 4,
  marginBottom: 4,
};

export const wrapperStyles = {
  display: 'flex',
  gap: '20px',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

export const infoCardStyles = {
  width: '100%',
  height: '350px',
};

export const infoCardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '30px',
};
