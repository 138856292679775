import { useState } from 'react';
import { Layout } from 'components/Layout';
import { HostingOpportunitiesTable } from 'components/Tables/HostingOpportunitiesTable';
import { HardwareOpportunitiesTable } from 'components/Tables/HardwareOpportunitiesTable';

export const Opportunities = () => {
  const [hardwareChange, setHardwareChange] = useState(false);

  return (
    <Layout
      backgroundChildren={
        <HostingOpportunitiesTable
          hardwareChange={hardwareChange}
          setHardwareChange={setHardwareChange}
        />
      }
    >
      <HardwareOpportunitiesTable
        setHardwareChange={setHardwareChange}
        hardwareChange={hardwareChange}
      />
    </Layout>
  );
};
