import { useEffect, useMemo, useState } from 'react';
import { Layout } from 'components/Layout';
import { useAuth } from 'shared/hooks/useAuth';
import { CampaignProspectTypes } from 'shared/utils/enum/campaingEnums';
import {
  BtcReserveHeader,
  DollarYieldHeader,
} from 'components/CampaignProspects/HomePageHeaders';
import { DollarYield } from 'components/CampaignProspects/DollarYield';
import { BtcReserve } from 'components/CampaignProspects/BtcReserve';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';

export const CampaignProspectHome = () => {
  const { user } = useAuth();
  const [dates, setDates] = useState([]);
  const { showSnackbar } = useNotification();

  const campaignType = useMemo(() => {
    return user.campaignProspect.type;
  }, [user]);

  const fetchCalendarSlots = async () => {
    setDates([]);
    try {
      const data = await UsersService.getUserCalendarSlots();
      setDates(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    fetchCalendarSlots();
  }, []);

  return (
    <Layout
      backgroundChildren={
        campaignType === CampaignProspectTypes.DollarYield ? (
          <DollarYieldHeader />
        ) : (
          <BtcReserveHeader />
        )
      }
    >
      {campaignType === CampaignProspectTypes.DollarYield ? (
        <DollarYield dates={dates} />
      ) : (
        <BtcReserve dates={dates} />
      )}
    </Layout>
  );
};
