import { Box, Typography } from '@mui/material';
import phoneCodes from 'shared/constants/phone-number-codes.json';
import { ControlledSelect } from '../Select';
import Flag from 'react-world-flags';
import { ControlledTextField } from '../TextField';
import {
  containerStyles,
  errorLineStyles,
  flagStyles,
  inputsContainer,
  labelStyles,
  selectContainer,
  selectStyles,
  textFieldStyles,
} from './styles';

export const ContractNumberInput = (props: any) => {
  const { control, errors, dark, label, name } = props;
  const { phoneNumberCodes } = phoneCodes;

  const options = phoneNumberCodes.map((item: any) => ({
    name: (
      <Box display="flex" alignItems="center" gap={1}>
        <Flag code={item.code} style={flagStyles} />({item.dial_code})
      </Box>
    ),
    value: item.dial_code,
  }));

  return (
    <Box sx={containerStyles}>
      <Typography sx={labelStyles(dark)}>{label}</Typography>
      <Box sx={inputsContainer}>
        <Box sx={selectContainer}>
          <ControlledSelect
            control={control}
            name="code"
            error={errors}
            dark={dark}
            options={options}
            defaultValue={options[0].value}
            sx={selectStyles}
          />
        </Box>
        <ControlledTextField
          control={control}
          error={errors}
          name={name}
          fullWidth
          dark
          sx={textFieldStyles}
        />
      </Box>
      {errors.phoneNumber && (
        <Typography sx={errorLineStyles}>
          {errors.phoneNumber.message}
        </Typography>
      )}
    </Box>
  );
};
