import { useMemo } from 'react';
import { Box, Button, Skeleton, SvgIcon, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatNumberToLocale, formatUsd } from 'shared/utils/formatter';
import { useNotification } from 'shared/hooks/useNotification';
import { routesMapping } from 'routes/mappings';
import { InfoCard } from 'components/Cards/InfoCard';
import { CopyIcon } from 'components/Icons/Copy';
import { makeUrl } from 'routes/makeUrl';
import { backgroundStyles } from '../styles';
import {
  walletBalanceStyles,
  walletAddressStyles,
  walletUsdValueStyles,
  walletAddressInfoStyles,
  walletInfoWrapperStyles,
  walletBalanceValueStyles,
  cardContentWrapperStyles,
  walletBalanceWrapperStyles,
  walletAddressCopyButtonStyles,
  transactionHistoryButtonStyles,
  transactionHistoryWrapperStyles,
} from './styles';

type Props = {
  wallet: {
    amountInCoin: number;
    walletAddress: string;
    amountInUsd: number;
    isWalletEmpty?: boolean;
  };
  selectedCurrency: any;
};

export const AffiliateWalletBalance = ({ wallet, selectedCurrency }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { affiliateId } = useParams();
  const { showSnackbar } = useNotification();

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    showSnackbar('Address Copied', 'success');
  };

  const handleNavigate = () => {
    const queryParam = `currency=${selectedCurrency.currency}`;

    if (location.pathname.includes('affiliate-view')) {
      return navigate(
        `${makeUrl(routesMapping.adminViewAffiliateOperationTransactions, {
          affiliateId,
        })}?${queryParam}`,
      );
    }

    return navigate(`${routesMapping.affiliateTransactions}?${queryParam}`);
  };

  const isWalletInvalid = useMemo(() => {
    return (
      wallet?.walletAddress === 'No wallet address found' ||
      wallet?.walletAddress === 'Invalid wallet address' ||
      !wallet?.walletAddress
    );
  }, [wallet]);

  const handleWalletAddressCopy = () => {
    if (!isWalletInvalid) handleCopy(wallet.walletAddress);
  };

  return (
    <InfoCard
      headerColor="white"
      infoIconColor="white"
      showDivider={false}
      showTime={false}
      fullHeight
      styles={backgroundStyles}
      content={
        <Box sx={cardContentWrapperStyles}>
          <Typography sx={walletBalanceStyles} variant="h5">
            {selectedCurrency.coinName} wallet balance
          </Typography>
          <Box sx={walletBalanceWrapperStyles}>
            <Box>
              {!wallet ? (
                <Skeleton variant="rectangular" height={50} />
              ) : (
                <Box>
                  <Typography sx={walletBalanceValueStyles}>
                    {formatNumberToLocale(wallet.amountInCoin, 6)}{' '}
                    {selectedCurrency.coinAbb}
                  </Typography>
                  <Typography sx={walletUsdValueStyles}>
                    ≈ {formatUsd(wallet.amountInUsd)} USD
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {wallet ? (
            <Box sx={walletInfoWrapperStyles}>
              <Box
                sx={walletAddressInfoStyles}
                onClick={handleWalletAddressCopy}
              >
                <Typography sx={walletAddressStyles}>
                  {wallet.walletAddress}
                </Typography>
                {!isWalletInvalid && (
                  <Typography sx={walletAddressCopyButtonStyles}>
                    <SvgIcon>
                      <CopyIcon />
                    </SvgIcon>
                  </Typography>
                )}
              </Box>
              <Box sx={transactionHistoryWrapperStyles}>
                <Button
                  disabled={isWalletInvalid}
                  type="button"
                  sx={transactionHistoryButtonStyles}
                  onClick={handleNavigate}
                >
                  Transaction history
                </Button>
              </Box>
            </Box>
          ) : (
            <Skeleton variant="rectangular" height={65} />
          )}
        </Box>
      }
    />
  );
};
