import { colors } from 'shared/constants/theme';
import Elipse from 'assets/images/elipse.png';

export const titleStyles = {
  fontSize: {
    xl: 28,
    lg: 28,
    md: 28,
    sm: 20,
    xs: 20,
  },
  fontWeight: 500,
  color: colors.black,
  marginTop: '24px',
  marginBottom: '16px',
};

export const descriptionStyles = {
  fontSize: {
    xl: 18,
    lg: 18,
    md: 18,
    sm: 14,
    xs: 14,
  },
  fontWeight: 400,
  color: colors.black,
  width: '45%',
};

export const cardsContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '16px',
  columnGap: '16px',
};

export const cardContainer = {
  width: {
    xl: 'calc((100% - 16px) / 2)',
    lg: 'calc((100% - 16px) / 2)',
    md: 'calc((100% - 16px) / 2)',
    sm: '100%',
    xs: '100%',
  },

  padding: {
    xl: '20px',
    lg: '20px',
    md: '20px',
    sm: '16px',
    xs: '16px',
  },
  backgroundColor: colors.lightGrey,
  borderRadius: '12px',
};

export const cardTitleContainer = {
  display: 'flex',
  columnGap: '8px',
  marginBottom: '24px',
  alignItems: 'center',
};

export const countryNameStyles = {
  fontSize: {
    xl: '24px',
    lg: '24px',
    md: '24px',
    sm: '18px',
    xs: '18px',
  },
  fontWeight: 500,
};

export const cardDescription = {
  fontSize: {
    xl: '16px',
    lg: '16px',
    md: '16px',
    sm: '14px',
    xs: '14px',
  },
  fontWeight: 400,
  marginTop: '24px',
};

export const innerCardStyles = {
  backgroundColor: colors.lightGreen,
  borderRadius: '10px',
  backgroundImage: `url(${Elipse})`,
  backgroundSize: {
    xl: '350px 350px',
    lg: '350px 350px',
    md: '350px 350px',
    sm: '250px 250px',
    xs: '250px 250px',
  },
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 0 top -50px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
};

export const sectionContainer = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
};

export const sectionTitle = {
  color: colors.lightGrey,
  fontSize: {
    xl: '16px',
    lg: '16px',
    md: '16px',
    sm: '14px',
    xs: '14px',
  },
  fontWeight: 400,
};

export const sectionValue = {
  color: colors.lightGrey,
  fontSize: {
    xl: '20px',
    lg: '20px',
    md: '20px',
    sm: '18px',
    xs: '18px',
  },
  fontWeight: 600,
};

export const bookButtonStyles = {
  fontSize: '16px',
  fontWeight: 500,
  backgroundColor: colors.white,
  border: '1px solid #eaeaea',
  borderRadius: '6px',
  width: '100%',
  color: colors.black,
  marginTop: '24px',
};

export const flagStyles = { width: 45, height: 32, borderRadius: 2 };
