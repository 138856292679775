export const containerStyles = {
  background: '#E4F3EB',
  width: '162px',
  height: '162px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '50px 0',
};

export const iconStyles = {
  margin: 0,
};
