import { colors } from 'shared/constants/theme';

export const defaultLineChartStyles: any = {
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 0,
      hoverBackgroundColor: '#84d0aa',
      hoverBorderColor: 'rgb(255, 255, 255)',
      borderWidth: 0,
      hoverRadius: 8,
      hoverBorderWidth: 5,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      caretSize: 0,
      backgroundColor: colors.white,
      borderColor: '#84d0aa',
      borderWidth: 1,
      titleColor: '#818181',
      bodyColor: '#84d0aa',
      footerColor: colors.white,
      displayColors: false,
      titleFont: () => ({
        size: 14,
        weight: 400,
      }),
      bodyFont: {
        size: 16,
      },
    },
  },
};

export const setLinearGradientColor = (context: any) => {
  const { chart } = context;
  if (!context.chart.chartArea) return;

  const {
    ctx,
    chartArea: { top, bottom },
  } = chart;
  const gradient = ctx.createLinearGradient(0, top, 0, bottom);

  gradient.addColorStop(0, 'rgb(17, 186, 95, 0.8)');
  gradient.addColorStop(1, 'rgb(17, 186, 95, 0)');
  return gradient;
};

export const getDefaultChartOptions = (context: any) => {
  const { chart } = context;
  if (!context.chart.chartArea) return;

  const {
    ctx,
    chartArea: { top, bottom },
  } = chart;
  const gradient = ctx.createLinearGradient(0, top, 0, bottom);

  gradient.addColorStop(0, 'rgb(92, 127, 251, 0.8)');
  gradient.addColorStop(1, 'rgb(194, 206, 248, 0.2)');
  return gradient;
};
