import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { ErrorOutline } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import { formatNumber, formatNumberToLocale } from 'shared/utils/formatter';
import { getTimeZone } from 'shared/utils/getTimeZone';
import { colors } from 'shared/constants/theme';
import BitcoinService from 'shared/services/bitcoin.service';
import {
  defaultLineChartStyles,
  setLinearGradientColor,
} from 'components/Charts/styles';
import { linePlugin } from 'components/Charts/plugins';
import { Card } from '../Card';
import { subtitleStyles } from '../styles';
import {
  dateStyles,
  priceStyles,
  filterStyles,
  wrapperStyles,
  containerStyles,
  errorIconStyles,
  priceContainerStyles,
  chartContainerStyles,
} from './styles';

const filterDates = ['1D', '5D', '1M', '6M', 'YTD', '1Y', '5Y'];

export const BitcoinInfoCard = (props: any) => {
  const { styles, currency, currencyValue, coinAbb } = props;

  const [bitcoinData, setBitcoinData] = useState<any>();
  const [bitcoinChart, setBitcoinChart] = useState<any>([]);
  const [filter, setFilter] = useState('1D');

  const data = {
    labels: bitcoinChart.map((d: any) => d.timestamp),
    datasets: [
      {
        borderColor: colors.lightGreen,
        fill: true,
        pointBackgroundColor: colors.lightGreen,
        pointRadius: 0,
        backgroundColor: (context: any) => setLinearGradientColor(context),
        borderWidth: 1.5,
        data: bitcoinChart.map((d: any) => d.value),
      },
    ],
  };

  useEffect(() => {
    getBitcoinData();
  }, []);

  const getBitcoinData = async () => {
    try {
      const { chart, ...data } = await BitcoinService.getBitcoinData({
        currency: currencyValue,
      });
      setBitcoinChart(chart);
      return setBitcoinData(data);
    } catch (ex) {}
  };

  const filterBitcoinData = async (date: any) => {
    setFilter(date);
    setBitcoinChart([]);
    const { chart } = await BitcoinService.getDateFilteredBitcoinChart({
      date,
      currency: currencyValue,
    });
    return setBitcoinChart(chart);
  };

  const differentiator = useMemo(
    () =>
      bitcoinData
        ? {
            color: bitcoinData.priceChange24h > 0 ? 'secondary' : 'error',
            growth: bitcoinData.priceChange24h > 0 ? '+' : '',
          }
        : {},
    [bitcoinData],
  );

  const formatPrice = (item: any, coinAbb: string) => {
    const rawValue = Number(item.raw);
    const formattedValue =
      rawValue !== 0 ? formatNumberToLocale(rawValue, 2) : '0';

    return `${coinAbb} Price ${formattedValue} $`;
  };

  return (
    <Card styles={styles}>
      <Box sx={wrapperStyles}>
        <Box sx={containerStyles}>
          <Box>
            <Typography sx={subtitleStyles} variant="h5">
              {currency} Price Today
            </Typography>
            {bitcoinData ? (
              <Box sx={priceContainerStyles}>
                <Typography sx={priceStyles} variant="h5">
                  {formatNumber(bitcoinData.currentPrice)} USD
                </Typography>
                <Typography fontSize={15} color={differentiator.color}>
                  <Box display="flex" alignItems="center" columnGap={1}>
                    {`${differentiator.growth}${formatNumber(
                      bitcoinData.priceChange24h,
                    )} (${formatNumber(
                      bitcoinData.priceChangePercentage24h,
                    )}%)`}
                  </Box>
                </Typography>
              </Box>
            ) : (
              <Skeleton variant="rectangular" height={40} />
            )}
            <Box sx={filterStyles}>
              {filterDates.map((date, index) => (
                <>
                  <Typography
                    onClick={() => filterBitcoinData(date)}
                    sx={dateStyles(filter === date)}
                  >
                    {date}
                  </Typography>
                  {index !== filterDates.length - 1 && (
                    <Divider orientation="vertical" flexItem />
                  )}
                </>
              ))}
            </Box>
            <Typography marginTop={3} marginBottom={1} color={colors.grey}>
              {moment().format(`dddd - MMM D, HH:mm [${getTimeZone()}]`)}
            </Typography>
          </Box>
          <Tooltip title={`${currency} Price`} arrow enterTouchDelay={0}>
            <ErrorOutline sx={errorIconStyles} />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={chartContainerStyles}>
        {!bitcoinChart.length ? (
          <Skeleton variant="rectangular" height="100%" />
        ) : (
          <Line
            data={data}
            options={{
              elements: defaultLineChartStyles.elements,
              maintainAspectRatio: false,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              plugins: {
                ...defaultLineChartStyles.plugins,
                tooltip: {
                  ...defaultLineChartStyles.plugins.tooltip,
                  callbacks: {
                    title: (item: any) => {
                      const label = item[0].label;

                      return moment.unix(+label).format('ddd, MMM DD HH:mm');
                    },
                    label: (item: any) => formatPrice(item, coinAbb),
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    minRotation: 0,
                    maxRotation: 0,
                    maxTicksLimit: 7,
                    callback(tickValue) {
                      return moment
                        .unix(data.labels[tickValue])
                        .format(filter !== '1D' ? 'MMM DD' : 'HH:mm');
                    },
                  },
                },
                y: {
                  ticks: {
                    callback: (label) => `${label.toLocaleString('en-US')} $`,
                  },
                },
              },
            }}
            plugins={[linePlugin]}
          />
        )}
      </Box>
    </Card>
  );
};
