import { colors } from 'shared/constants/theme';

export const layoutStyles = {
  fontSize: {
    xs: 32,
    sm: 40,
    md: 48,
    lg: 52,
  },
  color: colors.white,
  marginBottom: '60px',
};
