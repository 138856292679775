import { SectionHeading } from 'components/SectionHeading';
import { useMemo } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { BitcoinTransactions } from './BitcoinTransactions';
import { BitcoinInfoCard } from 'components/Cards/BitcoinInfoCard';
import moment from 'moment';
import { InfoCard } from 'components/Cards/InfoCard';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { Currency } from 'shared/utils/enum/coinEnums';
import { colors } from 'shared/constants/theme';

const EarnedBtcStatistic = (props: any) => {
  const { value, date, title, coinAbb } = props;

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <InfoCard
        showDivider={false}
        showTime={false}
        styles={{ height: '195px' }}
        info={title}
        subtitle={title}
        content={
          <>
            {date !== null ? (
              <Typography color={colors.grey}>{date}</Typography>
            ) : (
              <Skeleton />
            )}
            {value !== null ? (
              <Typography
                sx={{
                  fontSize: {
                    xl: '40px',
                    lg: '35px',
                    md: '25px',
                    sm: '45px',
                    xs: '35px',
                  },
                }}
                fontWeight={500}
                color="secondary"
              >
                {`+${value} ${coinAbb}`}
              </Typography>
            ) : (
              <Skeleton height={75} />
            )}
          </>
        }
      />
    </Grid>
  );
};

export const EarnedBtc = (props: any) => {
  const { statistics, chart, coinAbb, currency, currencyValue } = props;

  const handleCurrencyValue = (value: number) =>
    formatNumberToLocale(value, currencyValue === Currency.Bitcoin ? 6 : 3);

  const sections = useMemo(
    () => [
      {
        date: moment().subtract(1, 'day').format('MMMM DD'),
        title: `Past day`,
        value: statistics ? handleCurrencyValue(statistics.pastDay) : null,
      },
      {
        date: `${moment().subtract(7, 'days').format('MMMM DD')} - ${moment().subtract(1, 'day').format('MMMM DD')}`,
        title: 'Past 7 days',
        value: statistics
          ? handleCurrencyValue(statistics.pasySevenDays)
          : null,
      },
      {
        date: `${moment().subtract(30, 'days').format('MMMM DD')} - ${moment().subtract(1, 'day').format('MMMM DD')}`,
        title: 'Past 30 days',
        value: statistics
          ? handleCurrencyValue(statistics.pastThirtyDays)
          : null,
      },
    ],
    [statistics],
  );

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {sections.map((section) => (
          <EarnedBtcStatistic {...section} coinAbb={coinAbb} />
        ))}
      </Grid>
      <SectionHeading name={'Updates'} />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <BitcoinTransactions chart={chart} coinAbb={coinAbb} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <BitcoinInfoCard
            styles={{ height: '600px' }}
            showFilter
            marketSummary={true}
            coinAbb={coinAbb}
            currencyValue={currencyValue}
            currency={currency}
          />
        </Grid>
      </Grid>
    </>
  );
};
