export const styles = {
  fontSize: {
    xl: 28,
    lg: 28,
    md: 28,
    sm: 20,
    xs: 20,
  },
  lineHeight: 1,
  fontWeight: 600,
};
