import { Controller } from 'react-hook-form';
import { Box, TextField as MuiTextField, Typography } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const ControlledTextField = (props: any) => {
  const {
    control,
    disabled,
    name,
    label,
    labelColor = '#666666',
    error = {},
    placeholder,
    disableAutocomplete,
    required,
    defaultValue,
    textFieldProps = {},
    type = 'text',
    dark = false,
    width,
    maxLength,
    onKeyDown,
    sx = {},
  } = props;

  const finalInputProps = {
    maxLength,
    readOnly: disableAutocomplete,
    ...textFieldProps?.inputProps,
  };

  const finalSx = {
    ...(dark && {
      '& .MuiInputBase-input': {
        color: 'white',
        WebkitTextFillColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${colors.grey} !important`,
          borderWidth: '1px !important',
        },
        '&.Mui-focused fieldset': {
          borderColor: `${colors.grey} !important`,
        },
        '&.Mui-disabled fieldset': {
          borderColor: `${colors.grey} !important`,
        },
      },
    }),
    ...textFieldProps?.sx,
    ...sx,
  };

  const handleFocus = (e: any) => {
    e.target.addEventListener(
      'wheel',
      (e: any) => {
        e.preventDefault();
      },
      { passive: false }
    );

    if (disableAutocomplete) {
      e.target.removeAttribute('readonly');
    }
  };

  const handleKeyDown = (e: any) => {
    if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
      e.preventDefault();
    }

    if (onKeyDown) onKeyDown(e);
  };

  return (
    <Box
      width={width || '100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {label && (
        <Box display="flex" columnGap={0.5} marginY={0.5}>
          <Typography
            fontWeight="500"
            fontSize="16px"
            lineHeight="15px"
            color={dark ? colors.lightGrey : labelColor}
            marginTop="10px"
          >
            {label}
          </Typography>
          {required && (
            <Typography fontSize="15px" color="#ff4242">
              *
            </Typography>
          )}
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiTextField
            placeholder={placeholder}
            type={type}
            variant="outlined"
            fullWidth
            margin="dense"
            inputProps={finalInputProps}
            disabled={disabled}
            error={!!error?.[name]}
            autoComplete={disableAutocomplete ? 'off' : ''}
            helperText={error?.[name]?.message ?? ''}
            sx={finalSx}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            {...field}
            {...textFieldProps}
          />
        )}
      />
    </Box>
  );
};
