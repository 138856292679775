import { Box, Typography } from '@mui/material';
import ethiopiaFlag from 'assets/images/flags/ethiopia.svg';
import kazakhstanFlag from 'assets/images/flags/kazakhstan.svg';
import uaeFlag from 'assets/images/flags/UAE.svg';
import usaFlag from 'assets/images/flags/USA.svg';
import { BtcReserveCard } from './BtcReserveCard';
import { cardsContainer, descriptionStyles, titleStyles } from './styles';
import { useEffect, useState } from 'react';
import BitcoinService from 'shared/services/bitcoin.service';
import { useNotification } from 'shared/hooks/useNotification';
import { progressContainerStyles, progressStyles } from '../DollarYield/styles';
import { Progress } from 'components/Progress';

export const BtcReserve = (props: any) => {
  const { dates } = props;
  const [simulations, setSimulations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useNotification();

  const fetchBtcReserves = async () => {
    setSimulations([]);
    try {
      setLoading(true);
      const data = await BitcoinService.getBitcoinReserve();

      setSimulations(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBtcReserves();
  }, []);

  return (
    <Box>
      {loading ? (
        <Box sx={progressContainerStyles}>
          <Progress sx={progressStyles} size={30} />
        </Box>
      ) : (
        <Box sx={cardsContainer}>
          {simulations.map((item, index) => (
            <BtcReserveCard key={index} btcReserveItem={item} dates={dates} />
          ))}
        </Box>
      )}
      <Box>
        <Typography sx={titleStyles}>Why Mine Instead of Buy?</Typography>
        <Typography sx={descriptionStyles}>
          Mining Bitcoin allows you to accumulate BTC at a lower cost,
          increasing your long-term USD value as Bitcoin appreciates.
        </Typography>
      </Box>
    </Box>
  );
};
