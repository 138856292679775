export const wrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
};

export const infoContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
};

export const logoStyles = {
  width: '30px',
  height: '30px',
};
