import { colors } from 'shared/constants/theme';

export const menuItemStyles = (dark: boolean) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...(dark && {
    backgroundColor: 'transparent',
    color: colors.lightGrey,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: colors.lightGreen,
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected:hover': {
      backgroundColor: `${colors.lightGreen} !important`,
      color: `${colors.white} !important`,
    },
  }),
});

export const markIconStyles = { ml: 1, display: 'flex' };

export const muSelectStyles = (borderStyles: any, dark: any) => {
  return {
    border: borderStyles,
    marginTop: '8px',
    marginBottom: '4px',
    borderRadius: '8px',
    color: dark && colors.lightGrey,
    '.MuiSelect-icon': {
      color: dark ? colors.lightGrey : 'inherit',
    },
  };
};

export const paperStyles = (menuWidth: any, dark: any) => {
  return {
    width: menuWidth,
    background: dark ? colors.black : colors.white,
    color: dark ? colors.lightGrey : colors.black,
    border: `1px solid ${dark ? colors.grey : colors.black}`,
    borderRadius: '6px',
    marginTop: '10px !important',
  };
};

export const asteriskStyles = {
  fontSize: '15px',
  color: colors.errorRed,
};

export const labelStyles = (dark: any) => {
  return {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '15px',
    color: dark ? colors.lightGrey : '#666666',
    marginTop: '10px',
  };
};

export const errorTextStyles = { marginX: '14px' };

export const labelContainerStyles = {
  display: 'flex',
  columnGap: 0.5,
  my: 0.5,
};

export const containerStyles = { width: '100%' };
