import { Box, Typography } from '@mui/material';
import {
  headerDescriptionStyles,
  headerTitleStyles,
  headerContainer,
} from '../styles';

export const DollarYieldHeader = () => {
  return (
    <Box>
      <Typography sx={headerTitleStyles}>
        Earn Consistent Dollar Yield Through Mining-as-a-Service!
      </Typography>
      <Typography sx={headerDescriptionStyles}>
        Generate predictable USD returns with secure and transparent crypto
        mining operations.
      </Typography>
    </Box>
  );
};

export const BtcReserveHeader = () => {
  return (
    <Box sx={headerContainer}>
      <Typography sx={headerTitleStyles}>
        Bitcoin Strategic Reserve: Mine at a Lower Cost
      </Typography>
      <Typography sx={headerDescriptionStyles}>
        Acquiring Bitcoin through mining with Hearst allows you to secure BTC at
        a significantly lower cost than purchasing it from the market. Take
        advantage of location-based pricing and optimize your strategic BTC
        reserve.
      </Typography>
    </Box>
  );
};
