import moment from 'moment';
import { Box, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { getTimeZone } from 'shared/utils/getTimeZone';
import { colors } from 'shared/constants/theme';
import { Card } from 'components/Cards/Card';
import { subtitleStyles } from '../styles';
import {
  dividerStyles,
  errorIconStyles,
  infoWrapperStyles,
  infoContainerStyles,
  contentContainerStyles,
} from './styles';

export const InfoCard = (props: any) => {
  const {
    subtitle,
    showDivider = true,
    showTime = true,
    info,
    styles,
    content,
    fullHeight,
    headerColor,
    infoIconColor,
    hasOverflow,
    hasAdditionalSpace = true,
    link,
    linkText,
  } = props;

  return (
    <Card styles={styles}>
      {(subtitle || info || showDivider) && (
        <Box sx={showDivider ? dividerStyles : {}}>
          <Box sx={infoWrapperStyles(hasAdditionalSpace)}>
            <Box sx={infoContainerStyles}>
              {subtitle ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    sx={{
                      ...subtitleStyles,
                      color: headerColor,
                    }}
                    variant="h5"
                  >
                    {subtitle}
                  </Typography>
                  {link && linkText && (
                    <Link target="blank" href={link}>
                      <Typography color={colors.grey}>{linkText}</Typography>
                    </Link>
                  )}
                </Stack>
              ) : (
                <Skeleton variant="rectangular" height={40} width="100%" />
              )}
              {showTime && (
                <Typography
                  marginTop={3}
                  marginBottom={showDivider && 1}
                  color={colors.grey}
                >
                  {moment().format(`dddd - MMM D, HH:mm [${getTimeZone()}]`)}
                </Typography>
              )}
            </Box>
            {info && (
              <Tooltip title={info} arrow enterTouchDelay={0}>
                <ErrorOutline sx={errorIconStyles(infoIconColor)} />
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
      <Box sx={contentContainerStyles(fullHeight, hasOverflow)}>{content}</Box>
    </Card>
  );
};
