import { Typography } from '@mui/material';
import { styles } from './styles';

export const SectionHeading = (props: any) => {
  const { name, sx } = props;

  return (
    <Typography
      sx={{
        ...styles,
        ...sx,
      }}
    >
      {name}
    </Typography>
  );
};
