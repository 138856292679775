import { colors } from 'shared/constants/theme';

export const cardContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const walletBalanceStyles = {
  fontSize: '20px',
  color: colors.white,
  fontWeight: '500',
};

export const walletBalanceWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const walletBalanceValueStyles = {
  fontSize: '32px',
  fontWeight: '600',
  color: colors.white,
  lineHeight: '56px',
};

export const walletUsdValueStyles = {
  fontSize: '24px',
  fontWeight: '400',
  color: colors.white,
};

export const walletInfoWrapperStyles = {
  display: {
    xs: 'block',
    sm: 'block',
    md: 'flex',
    lg: 'flex',
    xl: 'flex',
  },
  justifyContent: 'space-between',
  gap: '20px',
};

export const walletAddressInfoStyles = {
  fontSize: '24px',
  fontWeight: '400',
  borderRadius: '10px',
  display: 'flex',
  overflow: 'hidden',
  flexShrink: '1',
  padding: '10px 0px',
  columnGap: '38px',
  marginBottom: {
    xs: '20px',
    sm: '20px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
  cursor: 'pointer',
};

export const walletAddressStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'white',
  fontSize: '18px',
  fontWeight: '400',
  width: '100%',
};

export const walletAddressCopyButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
};

export const transactionHistoryWrapperStyles = {
  minWidth: '209px',
};

export const transactionHistoryButtonStyles = {
  color: colors.darkBlue,
  backgroundColor: colors.white,
  height: '100%',
  padding: '10px 24px',
  fontWeight: '400',
  fontSize: '18px',
  width: '100%',
  borderRadius: '10px',
  letterSpacing: '-0.6px',
  '&:hover': {
    backgroundColor: `${colors.darkGreen} !important`,
  },
};
