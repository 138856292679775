import { colors } from 'shared/constants/theme';

export const headerContainer = {
  width: '80%',
};

export const headerTitleStyles = {
  fontSize: {
    xl: '42px',
    lg: '42px',
    md: '42px',
    sm: '30px',
    xs: '30px',
  },
  fontWeight: 500,
  color: colors.lightGrey,
};

export const headerDescriptionStyles = {
  fontSize: {
    xl: '20px',
    lg: '20px',
    md: '20px',
    sm: '16px',
    xs: '16px',
  },
  fontWeight: 400,
  color: colors.lightGrey,
};
