export const subtitleStyles = {
  fontSize: {
    xl: 20,
    lg: 20,
    md: 15,
    sm: 25,
    xs: 25,
  },
  fontWeight: 500,
};
