import { Box } from '@mui/material';
import { cardStyles } from './styles';

export const Card = (props: any) => {
  const { children, styles } = props;

  return (
    <Box
      sx={{
        ...cardStyles,
        ...styles,
      }}
    >
      {children}
    </Box>
  );
};
