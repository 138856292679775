import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { Activity } from 'components/CustomerDetails/Activity';
import { Details } from 'components/CustomerDetails/CutomerDetails';
import { EarnedBtc } from 'components/CustomerDetails/EarnedBTC';
import { Upfront } from 'components/CustomerDetails/types';
import { Layout } from 'components/Layout';
import { SectionHeading } from 'components/SectionHeading';
import { AdminEventsTable } from 'components/Tables/AdminEventsTable';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { Contracts } from './Contracts';
import { TotalEarnings } from './TotalEarnings';
import { Progress } from 'components/Progress';
import { useNotification } from 'shared/hooks/useNotification';
import ContractsService from 'shared/services/contracts.service';
import {
  activeTabStyles,
  tabsContainer,
  tabStyles,
  logoContainer,
  logoStyles,
} from './styles';
import { colors } from 'shared/constants/theme';
import { InvoicesTable } from 'components/Tables/InvoicesTable';

type Props = {
  customer?: {
    companyName: string;
    username: string;
    email: string;
  };
  upfront?: Upfront | null | undefined;
  owner?: {
    username: string;
  };
  hashrateData?: any;
  role?: UserRole;
  backNavigation: string;
  coins: any;
  setSelectedCurrency: any;
  selectedCurrency: any;
};
export const CustomersDetails = ({
  customer,
  owner,
  upfront,
  role,
  hashrateData,
  backNavigation,
  coins,
  selectedCurrency,
  setSelectedCurrency,
}: Props) => {
  const navigate = useNavigate();
  const [contractsChanged, setContractsChanged] = useState(false);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [contractsExist, setContractsExist] = useState<boolean>(false);

  const { customerId } = useParams();
  const { showSnackbar } = useNotification();

  const fetchContracts = async (search = '') => {
    try {
      setContractsExist(false);
      setContractsLoading(true);
      const { contracts } = await ContractsService.getContractsByUser(
        customerId!,
        {
          pageNumber: 1,
          limit: 5,
          search,
          currency: selectedCurrency.currency,
        },
      );

      if (contracts.length) setContractsExist(true);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setContractsLoading(false);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, [selectedCurrency]);

  return (
    <Layout
      backgroundChildren={
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <Button
            sx={{
              borderColor: colors.lightGrey,
              borderRadius: 2,
              padding: '5px',
              minWidth: 'unset',
            }}
            variant="outlined"
            onClick={() => navigate(backNavigation)}
          >
            <KeyboardArrowLeft />
          </Button>
          <SectionHeading
            sx={{
              color: 'white',
            }}
            name={customer?.companyName}
          />
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '60px',
          marginTop: '20px',
        }}
      >
        {role === UserRole.AFFILIATE ? <></> : <InvoicesTable admin={true} />}
        <Box sx={tabsContainer}>
          {coins.map((coin: any) => (
            <Box
              key={coin.currency}
              sx={
                selectedCurrency.currency === coin.currency
                  ? activeTabStyles
                  : upfront
                    ? tabStyles
                    : { ...tabStyles, opacity: 0.5 }
              }
              onClick={() => upfront && setSelectedCurrency(coin)}
            >
              <Box sx={logoContainer}>
                <img src={coin.logo} alt={coin.coinName} style={logoStyles} />
              </Box>
              <Typography
                sx={{
                  color:
                    selectedCurrency.currency === coin.currency
                      ? colors.black
                      : colors.white,
                  fontSize: {
                    xs: '10px',
                    sm: '10px',
                    md: '14px',
                    lg: '14px',
                    xl: '14px',
                  },
                }}
              >
                {coin.coinAbb}
              </Typography>
            </Box>
          ))}
        </Box>

        {contractsLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <Progress color="inherit" size={100} />
          </Box>
        ) : contractsExist ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '60px',
              marginTop: '20px',
            }}
          >
            <TotalEarnings
              totalBTC={upfront?.btcSinceBeginning}
              totalUSD={upfront?.upfrontTotal}
              coinAbb={selectedCurrency.coinAbb}
              isAffiliate={role === UserRole.AFFILIATE}
            />
            <EarnedBtc
              statistics={upfront}
              titleColor="black"
              coinAbb={selectedCurrency.coinAbb}
              isAffiliate={role === UserRole.AFFILIATE}
            />
            {role === UserRole.ADMIN && (
              <Box>
                <SectionHeading name={'Activity'} />
                <Activity
                  hashrateData={hashrateData}
                  currencyValue={selectedCurrency.currency}
                />
              </Box>
            )}
            <Contracts
              currency={selectedCurrency.currency}
              contractsChanged={contractsChanged}
            />
            {role === UserRole.ADMIN && (
              <>
                <AdminEventsTable currency={selectedCurrency.currency} />
              </>
            )}
            <Details
              contactPerson={customer?.username}
              email={customer?.email}
              signedBy={owner?.username}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '450px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{ color: '#fff', fontSize: '32px', fontWeight: '500' }}
            >
              No contracts for {selectedCurrency.coinName} created yet
            </Typography>
            {/* <Box
              sx={{
                color: '#000000',
                borderRadius: '10px',
                padding: '10px',
                paddingInline: '62px',
                backgroundColor: '#FFFFFF',
                cursor: 'pointer',
                marginTop: '30px',
              }}
              onClick={() => setShowModal(true)}
            >
              Create contract
            </Box>
            <CustomModal open={showModal} onClose={() => setShowModal(false)}>
              <ContractForm
                setShowModal={setShowModal}
                onFinish={() => setContractsChanged((prevState) => !prevState)}
              />
            </CustomModal> */}
          </Box>
        )}
      </Box>
    </Layout>
  );
};
