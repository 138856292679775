import { Box, IconButton, Input, InputAdornment } from '@mui/material';
import { colors } from 'shared/constants/theme';
import search from 'assets/images/search.svg';

import './index.css';

interface SearchInputProps {
  setValue: undefined | ((value: string) => void);
  value: string | undefined;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ setValue, value }: SearchInputProps) => {
  return (
    <Input
      placeholder="Search"
      className="searchInput"
      sx={{
        borderRadius: '5px',
        width: '210px',
        background: colors.white,
        flexDirection: 'row-reverse',
        color: colors.grey,
        padding: '4px 5px 4px 15px',

        '&::placeholder': {
          color: colors.grey,
          fontSize: 14,
          opacity: 1,
        },
        'input::placeholder': {
          color: colors.grey,
          fontSize: 14,
          opacity: 1,
        },
      }}
      onChange={(e) => setValue && setValue(e.target.value)}
      value={value}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <IconButton>
            <Box
              component="img"
              sx={{
                cursor: 'pointer',
                height: 20,
                width: 20,
                maxHeight: { xs: 17, md: 17 },
                maxWidth: { xs: 17, md: 17 },
              }}
              alt="search icon"
              src={search}
            />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
