import { colors } from 'shared/constants/theme';

export const modalContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '30px',
  flexDirection: 'column',
};

export const confirmButtonStyles = {
  backgroundColor: colors.lightGreen,
  color: colors.lightGrey,
  marginTop: '30px',
  width: '35%',
  ':disabled': {
    backgroundColor: colors.grey,
    color: colors.lightGrey,
  },
};

export const selectedDateStyles = { marginTop: '15px', fontWeight: 'bold' };
