import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { Progress } from 'components/Progress';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { useNotification } from 'shared/hooks/useNotification';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { CampaignAuthLayout } from 'components/Layout';
import { authButtonStyles } from 'shared/styles/button';
import * as yup from 'yup';
import { IFormInput } from 'pages/Login/type';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import dollarYieldImage from 'assets/images/dollarYieldCampaign.png';
import btcReserveImage from 'assets/images/bitcoinReserveCampaign.png';
import { CampaignProspectTypes } from 'shared/utils/enum/campaingEnums';
import AuthService from 'shared/services/auth.service';
import { EmailConfirmation } from './EmailConfirmation';
import {
  middleLineStyles,
  companyInputStyles,
  formContainerStyles,
  hideSelectBorderStyles,
  passwordInfoTextStyles,
  passwordInputContainer,
  usernameSectionStyles,
} from './styles';
import { ContractNumberInput } from 'components/ControlledInputs/ContactNumberInput';

const investmentOptions = [
  { name: '$100,000.00', value: '100000' },
  { name: '$200,000.00', value: '200000' },
  { name: '$300,000.00', value: '300000' },
];

export const CampaignSignUp = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationState, setVerificationState] = useState<boolean>(false);
  const { showSnackbar } = useNotification();
  const navigate = useNavigate();
  const { search } = useLocation();

  const campaignType = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('type');
  }, [search]);

  useEffect(() => {
    if (!campaignType) navigate(routesMapping.login);
  }, [campaignType, navigate]);

  const campaignTypeImage = useMemo(() => {
    if (campaignType === CampaignProspectTypes.DollarYield)
      return dollarYieldImage;

    return btcReserveImage;
  }, [campaignType]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').email().trim(),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is a required field'),
    firstName: yup.string().required('Name is a required field'),
    lastName: yup.string().required('Surname is a required field'),
    phoneNumber: yup.string().required('Contact number is a required field'),
    companyName: yup.string().required('Company name is a required field'),
    investment: yup
      .string()
      .required('Investment interest is a required field'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const email = useWatch({ control, name: 'email' });
  const password = useWatch({ control, name: 'password' });

  const onSubmit = async (payload: any) => {
    setIsLoading(true);

    try {
      const response = await AuthService.prospectSignUp({
        ...payload,
        prospect: campaignType,
        phoneNumber: `${payload.code}${payload.phoneNumber}`,
      });

      if (response.sent) {
        setVerificationState(true);
      } else {
        showSnackbar('Failed to send confirmation email', 'error');
      }
    } catch (e: any) {
      showSnackbar(e.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return verificationState ? (
    <EmailConfirmation
      email={email}
      password={password}
      setVerificationState={setVerificationState}
    />
  ) : (
    <CampaignAuthLayout image={campaignTypeImage}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formContainerStyles}>
          <Box sx={usernameSectionStyles}>
            <ControlledTextField
              control={control}
              name="firstName"
              label="Name"
              error={errors}
              placeholder="Enter your name"
              dark
            />
            <ControlledTextField
              control={control}
              name="lastName"
              label="Surname"
              error={errors}
              placeholder="Enter your surname"
              dark
            />
          </Box>
          <ControlledTextField
            control={control}
            placeholder="Enter your Email"
            name="email"
            label="Email"
            fullWidth
            error={errors}
            dark
          />
          <hr style={middleLineStyles} />
          <ContractNumberInput
            control={control}
            errors={errors}
            name="phoneNumber"
            dark
            label="Contact Number"
            placeholder="Enter your contact number"
          />
          <ControlledTextField
            control={control}
            sx={companyInputStyles}
            placeholder="Enter your company name"
            name="companyName"
            label="Company Name"
            error={errors}
            fullWidth
            dark
          />
          <ControlledSelect
            control={control}
            sx={hideSelectBorderStyles}
            name="investment"
            label="Investment Interest"
            placeholder="Select your investment interest"
            options={investmentOptions}
            error={errors}
            dark
          />
          <hr style={middleLineStyles} />
          <Box sx={passwordInputContainer}>
            <ControlledTextField
              control={control}
              defaultValue=""
              label="Password"
              name="password"
              placeholder="Enter password"
              error={errors}
              type={showPassword ? 'text' : 'password'}
              dark
              textFieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <SvgComponentSee />
                        ) : (
                          <SvgComponentHide />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Typography sx={passwordInfoTextStyles}>
              Minimum length in 8 characters.
            </Typography>
          </Box>
          <Button
            sx={authButtonStyles}
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
          >
            {!isLoading ? (
              'Get Started & See Opportunities'
            ) : (
              <Progress color="inherit" size={20} />
            )}
          </Button>
        </Box>
      </form>
    </CampaignAuthLayout>
  );
};
