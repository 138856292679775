import { colors } from 'shared/constants/theme';

export const threeRowGridItem = {
  item: true,
  sm: 12,
  xs: 12,
  md: 4,
  lg: 4,
  xl: 4,
};

export const twoRowGridItem = {
  item: true,
  sm: 12,
  xs: 12,
  md: 6,
  lg: 6,
  xl: 6,
};

export const tabsContainer = {
  backgroundColor: '#202a25',
  borderRadius: '20px',
  padding: '10px',
  display: 'flex',
};

export const activeTabStyles = {
  width: '50%',
  textAlign: 'center',
  padding: '12px',
  borderRadius: '10px',
  backgroundColor: colors.white,
  color: colors.darkBlue,
  cursor: 'pointer',
};

export const tabStyles = {
  width: '50%',
  textAlign: 'center',
  padding: '12px',
  color: colors.white,
  cursor: 'pointer',
};
