import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getColumns } from './columns';
import { Progress } from 'components/Progress';
import DashboardService from 'shared/services/dashboard.service';
import { useNotification } from 'shared/hooks/useNotification';

import './index.css';
import { RedesignedTable } from 'components/Tables/RedesignedTable';

type ButtonType = {
  value: number;
  isActive: boolean;
};

export const TransactionTable = (props: any) => {
  const { contracts, currency, coinAbb } = props;

  const [paymentData, setPaymentData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentFilteredData, setPaymentFilteredData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [activeContract, setActiveContract] = useState<any>(null);
  const [buttons, setButtons] = useState<ButtonType[]>([
    { value: 7, isActive: false },
    { value: 30, isActive: false },
    { value: 90, isActive: false },
  ]);
  const [contract, setContract] = useState<string>('');
  const { showSnackbar } = useNotification();
  const { customerId } = useParams();

  useEffect(() => {
    if (!contracts.length) return;

    setContract(contracts[0].name);
    setActiveContract(contracts[0]);
  }, [contracts]);

  const tableColumns = useMemo(() => getColumns(coinAbb), [coinAbb]);

  const filterLatestData = (date: number): void => {
    const selectedButton = buttons?.filter((button: ButtonType) => {
      button.isActive = date === button.value;
      return button;
    });

    setButtons(selectedButton);
    setPaymentFilteredData([]);
    const currentDate = new Date();
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - date);

    const filteredData = paymentData.filter(
      (entry: { timestamp: string | number | Date }) => {
        const entryDate = new Date(entry.timestamp);
        return entryDate >= ninetyDaysAgo && entryDate <= currentDate;
      },
    );

    setPaymentFilteredData(filteredData);
    setCurrentPage(1);
  };

  const handleChange = (event: SelectChangeEvent) => {
    contracts.map((item: { name: string }) => {
      if (item.name === event.target.value) {
        setActiveContract(item);
        setContract(event.target.value);
        return item;
      }
    });
    setCurrentPage(1);
  };

  const change = (name: string, val: number) => {
    if ('setCurrentPage' == name) {
      setCurrentPage(val);
    } else {
      setRowsPerPage(val);
      setCurrentPage(1);
    }
  };

  const getPaymentAll = async () => {
    setIsLoading(true);
    try {
      const response = await DashboardService.getDashboardPaymentAll(
        activeContract.subAccountUserId,
        {
          customerId,
          currency: currency,
        },
      );

      setPaymentData(response);
      setPaymentFilteredData(response);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeContract) {
      setPaymentFilteredData(null);
      getPaymentAll();
    }
  }, [activeContract]);

  return (
    <div className="wrapperTransactionTable">
      <div className="dashboard-div">
        <div className="dates">
          <Box
            sx={{
              fontSize: '20px',
              fontWeight: '600',
              marginRight: '20px',
              color: '#131613',
            }}
          >
            Time
          </Box>
          {buttons.map((button: ButtonType) => (
            <button
              style={
                button?.isActive
                  ? { border: '1px solid #1CBC29' }
                  : { border: '1px solid #E7E7E7' }
              }
              onClick={() => filterLatestData(button.value)}
              key={button.value}
            >
              {button.value} Days
            </button>
          ))}
          <Select
            value={contract}
            onChange={handleChange}
            IconComponent={KeyboardArrowDownIcon}
            sx={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              '& .MuiOutlinedInput-input': {
                paddingLeft: '32px',
                paddingRight: '60px !important',
              },
            }}
          >
            {contracts.map((contract: any) => (
              <MenuItem value={contract.name} key={contract.name}>
                {contract.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {isLoading ? (
        <Progress className="paymentProgress" size={24} />
      ) : !paymentFilteredData?.length ? (
        <p className="no_payment_hostory">
          There is no payment history{' '}
          {`${
            activeContract ? 'for ' + activeContract.name + ' contract' : ''
          }`}
          .
        </p>
      ) : (
        <Box sx={{ padding: '20px' }}>
          <RedesignedTable
            items={paymentFilteredData?.slice(
              (currentPage - 1) * rowsPerPage,
              (currentPage - 1) * rowsPerPage + rowsPerPage,
            )}
            tableName="Transaction Per Day"
            columns={tableColumns}
            totalPages={Math.ceil(paymentFilteredData?.length / rowsPerPage)}
            setCurrentPage={(val) => change('setCurrentPage', val)}
            setRowsPerPage={(val) => change('setRowsPerPage', val)}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            wrapped={false}
            addButton={false}
          />
        </Box>
      )}
    </div>
  );
};
