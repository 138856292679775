import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { CustomersTable } from 'components/Tables/CustomersTable';
import { headingStyles } from './styles';

export const Customers = () => {
  return (
    <Box>
      <SectionHeading name="Customers" sx={headingStyles} />
      <CustomersTable />
    </Box>
  );
};
