import { Box } from '@mui/material';
import logo from 'assets/images/login.png';
import { Header } from 'components/Layout/Header';
import { useAuth } from 'shared/hooks/useAuth';
import { Footer } from './Footer';
import { HearstLogo } from 'components/Icons/HearstLogo';
import { useMemo } from 'react';
import {
  authLayoutContainerStyles,
  authLayoutContentStyles,
  campaignLayoutContainer,
  campaignLayoutContentStyles,
  childerContainerStyles,
  imageContainerStyles,
  initialStyles,
  layoutContainer,
} from './styles';

export const Layout = (props: any) => {
  const { children, backgroundChildren } = props;
  const { user } = useAuth();

  const containerStyles = useMemo(() => {
    return { ...initialStyles };
  }, []);

  return (
    <Box sx={layoutContainer}>
      <Box>
        {user && <Header />}
        {backgroundChildren && (
          <Box sx={{ ...containerStyles }}>{backgroundChildren}</Box>
        )}
        <Box sx={childerContainerStyles}>{children}</Box>
      </Box>
      <Footer />
    </Box>
  );
};

export const AuthLayout = (props: any) => {
  const { children } = props;

  return (
    <Box className="layoutContainer" sx={authLayoutContainerStyles}>
      <Box sx={authLayoutContentStyles}>
        <HearstLogo />
        {children}
      </Box>
      <Box className="login-left-side">
        <img
          className="imgLogin login-image"
          src={logo}
          alt="login welcome back"
        />
      </Box>
    </Box>
  );
};

export const CampaignAuthLayout = (props: any) => {
  const { children, image } = props;

  return (
    <Box sx={campaignLayoutContainer}>
      <Box sx={campaignLayoutContentStyles}>
        <HearstLogo width={115} height={24} />
        {children}
      </Box>
      {image && (
        <Box sx={imageContainerStyles}>
          <img src={image} alt="login welcome back" />
        </Box>
      )}
    </Box>
  );
};
