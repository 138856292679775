import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Layout } from 'components/Layout';
import {
  fieldsStyle,
  fieldsStyleGray,
  titleSize,
  titleFontSize,
  titleLabelGap,
  emailMaxWidth,
  ContactDetailsBox,
} from './styles';
import UsersService from 'shared/services/users.service';
import { AdminEventsTable } from 'components/Tables/AdminEventsTable';

export const RenderField = (
  label: string,
  value?: string | number,
): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: titleLabelGap,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '50%',
          color: 'secondary.main',
          fontSize: titleFontSize,
        }}
      >
        {label}
      </Box>
      {value ? (
        <Box
          sx={{
            width: '50%',
            fontSize: titleFontSize,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: emailMaxWidth,
          }}
        >
          {value}
        </Box>
      ) : null}
    </Box>
  );
};

export const UserData = (): JSX.Element => {
  const { customerId: id } = useParams();

  const [user, setUser] = useState<Record<string, any>>({});

  const getUser = async () => {
    try {
      const user = await UsersService.getUser(id!);
      setUser(user);
    } catch (err) {}
  };

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '27px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '20px',
            rowGap: '20px',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={ContactDetailsBox}>
            <Box
              sx={{
                maxWidth: '1000',
                borderRadius: '10',
                border: '1px solid #E0E0E0',
              }}
            >
              <Box sx={{ ...fieldsStyle }}>
                {RenderField('Customer details')}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Email', user?.email)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Username', user?.username)}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Company', user?.companyName)}
              </Box>
              <Box
                sx={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  ...fieldsStyle,
                }}
              >
                {RenderField('Wallet Address', user?.walletAddress)}
              </Box>
            </Box>
          </Box>
        </Box>
        <AdminEventsTable />
      </Box>
    </Layout>
  );
};
