import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Statistics } from 'components/Statistics';
import { SectionHeading } from 'components/SectionHeading';
import { LiveUtcDateTime } from 'components/LiveUtcDateTime';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { headingStyles, headingWrapperStyles } from './styles';

export const AffiliateCustomerOperations = (props: any) => {
  const { upfrontTotal, totalSales } = props;

  const statisticsData = useMemo(
    () => [
      {
        title: 'Total sales',
        value:
          totalSales || totalSales === 0
            ? `$${formatNumberToLocale(totalSales, 2)}`
            : null,
        currency: 'USD',
      },
      {
        title: 'Total upfront payments',
        value:
          upfrontTotal || upfrontTotal === 0
            ? `$${formatNumberToLocale(upfrontTotal, 2)}`
            : null,
        currency: 'USD',
      },
    ],
    [upfrontTotal, totalSales],
  );

  return (
    <>
      <Box sx={headingWrapperStyles}>
        <SectionHeading sx={headingStyles} name="Your sales achievements" />
        <LiveUtcDateTime />
      </Box>
      <Statistics statistics={statisticsData} />
    </>
  );
};
