import { colors } from 'shared/constants/theme';

export const dividerStyles = {
  borderBottom: '1px solid #ECECEC',
  borderRadius: 0,
};

export const infoContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
};

export const infoWrapperStyles = (additionalSpace: boolean) => ({
  padding: additionalSpace ? 3 : 0,
  paddingBottom: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const errorIconStyles = (customColor?: string) => ({
  color: customColor ?? colors.grey,
});

export const contentContainerStyles = (
  fullHeight: boolean,
  hasOverflow: boolean,
) => ({
  overflowY: 'auto',
  padding: 3,
  height: fullHeight ? '100%' : 'unset',
  overflow: hasOverflow ? 'auto' : 'unset',
});
