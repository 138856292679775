import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { IAuthProviderProps, UserStatus } from './types';
import { accessToken } from 'shared/constants/localStorage';
import UsersService from 'shared/services/users.service';
import AuthService from 'shared/services/auth.service';

export const AuthContext = createContext({});

export const AuthProvider = (props: IAuthProviderProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const [user, setUser] = useState<Record<string, any> | null>(null);
  const [userStatus, setUserStatus] = useState<UserStatus>('loading');
  const location = useLocation();

  const whitelistedPaths = [
    routesMapping.login,
    routesMapping.forgotPassword,
    routesMapping.signUp,
    routesMapping.campaignSignUp,
  ];

  const logout = () => {
    localStorage.removeItem(accessToken);
    setUser(null);
    navigate(routesMapping.login);
  };

  const getCurrentUser = async () => {
    try {
      if (!localStorage.getItem(accessToken)) {
        if (whitelistedPaths.includes(location.pathname)) return;
        return navigate(routesMapping.login);
      }
      const user = await UsersService.me();
      setUser(user);
    } catch (ex) {
      setUser(null);
      logout();
    } finally {
      return setUserStatus('done');
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const login = async (payload: any) => {
    const { accessToken: token } = await AuthService.login(payload);
    localStorage.setItem(accessToken, token);
    await getCurrentUser();
  };

  const signUp = async (token: string) => {
    localStorage.setItem(accessToken, token);
    await getCurrentUser();
  };

  return (
    <AuthContext.Provider
      value={{
        userStatus,
        user,
        login,
        logout,
        getCurrentUser,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
