import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotification } from 'shared/hooks/useNotification';
import { SectionHeading } from 'components/SectionHeading';
import { Balances } from 'pages/Affiliate/AffiliateOperation/Balances';
import { coins } from 'shared/utils/currency';
import { Layout } from 'components/Layout';
import { Upfront } from 'components/CustomerDetails/types';
import { EarnedBtc } from 'components/CustomerDetails/EarnedBTC';
import BitcoinService from 'shared/services/bitcoin.service';
import contractsService from 'shared/services/contracts.service';
import {
  tabStyles,
  logoStyles,
  logoContainer,
  tabsContainer,
  activeTabStyles,
} from 'components/CustomerDetails/styles';
import { coinStyles, contentWrapperStyles, headingStyles } from './styles';

export const AffiliateOperation = () => {
  const [walletData, setWalletData] = useState<any>(null);
  const [upfront, setUpfront] = useState<Upfront>();
  const [selectedCurrency, setSelectedCurrency] = useState(coins[0]);

  const { affiliateId } = useParams();
  const { showSnackbar } = useNotification();

  const getWalletData = async () => {
    setWalletData(null);
    try {
      const { transactions, ...data } = await BitcoinService.getWalletData({
        currency: selectedCurrency.currency,
        customerId: affiliateId,
      });

      return setWalletData(data);
    } catch (err: any) {
      return showSnackbar(err.error, 'error');
    }
  };

  const getTotalUpfront = async () => {
    setUpfront(undefined);
    try {
      const affiliateUpfront = await contractsService.getAffiliateUpfront({
        currency: selectedCurrency.currency,
        customerId: affiliateId,
      });

      setUpfront(affiliateUpfront);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getWalletData();
    getTotalUpfront();
  }, [selectedCurrency]);

  return (
    <Layout
      backgroundChildren={
        <>
          <SectionHeading sx={headingStyles} name="Balances" />
          <Box sx={contentWrapperStyles}>
            <Box sx={tabsContainer}>
              {coins.map((coin: any) => (
                <Box
                  key={coin.currency}
                  sx={
                    selectedCurrency.currency === coin.currency
                      ? activeTabStyles
                      : upfront && walletData
                        ? tabStyles
                        : { ...tabStyles, opacity: 0.5 }
                  }
                  onClick={() => {
                    if (upfront && walletData) setSelectedCurrency(coin);
                  }}
                >
                  <Box sx={logoContainer}>
                    <img
                      src={coin.logo}
                      alt={coin.coinName}
                      style={logoStyles}
                    />
                  </Box>
                  <Typography
                    sx={coinStyles(selectedCurrency.currency === coin.currency)}
                  >
                    {coin.coinAbb}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Balances
              walletData={walletData}
              btcSinceBeginning={upfront?.btcSinceBeginning}
              selectedCurrency={selectedCurrency}
            />
          </Box>
        </>
      }
    >
      <EarnedBtc
        statistics={upfront}
        coinAbb={selectedCurrency.coinAbb}
        isAffiliate={true}
      />
    </Layout>
  );
};
