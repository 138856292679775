import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { useNotification } from 'shared/hooks/useNotification';
import AuthService from 'shared/services/auth.service';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { maskEmail } from 'shared/utils/formatter';
import { HearstIconBackground } from 'components/Icons/HearstIconBackground';
import { Progress } from 'components/Progress';
import { useAuth } from 'shared/hooks/useAuth';
import {
  backButtonStyles,
  buttonsContainer,
  confirmButtonStyles,
  emailTextContainerStyles,
  emailTextStyles,
  emailTitleTextStyles,
  logoContainer,
  sectionContainerStyles,
  startButtonStyles,
  styledBackground,
  textContainerStyles,
  textStyles,
  contentContainer,
} from './styles';
import { HearstLogo } from 'components/Icons/HearstLogo';

export const EmailConfirmation = ({
  email,
  setVerificationState,
  password,
}: any) => {
  const { showSnackbar } = useNotification();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState();

  const { signUp } = useAuth();

  const schema = yup.object().shape({
    confirmationCode: yup.string().required('Code is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ confirmationCode: string }>({
    resolver: yupResolver(schema),
  });

  const confirmationCode = useWatch({ control, name: 'confirmationCode' });

  const onSubmit = async (payload: { confirmationCode: string }) => {
    try {
      setLoading(true);
      const { emailToken } = await AuthService.confirmEmailCode({
        ...payload,
        email,
      });

      const { accessToken } = await AuthService.resetPassword({
        password,
        email,
        emailToken,
      });

      setAccessToken(accessToken);
      setIsConfirmed(true);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const finaliseSignUp = async () => {
    try {
      if (accessToken) {
        setLoading(true);
        await signUp(accessToken);
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styledBackground}>
      <Box sx={logoContainer}>
        <HearstLogo />
      </Box>
      <Box sx={contentContainer}>
        {isConfirmed ? (
          <Box sx={sectionContainerStyles}>
            <HearstIconBackground />
            <Box sx={textContainerStyles}>
              <Typography sx={textStyles}>Congratulations!</Typography>
              <Typography sx={textStyles}>
                Your account is successfully created!
              </Typography>
            </Box>
            <Button sx={startButtonStyles} onClick={() => finaliseSignUp()}>
              {!isLoading ? (
                'Let’s Start'
              ) : (
                <Progress color="inherit" size={20} />
              )}
            </Button>
          </Box>
        ) : (
          <Box sx={emailTextContainerStyles}>
            <Box>
              <Typography sx={emailTitleTextStyles}>
                Check your Email
              </Typography>
              <Typography sx={emailTextStyles}>
                We have sent a verification code to create an account to{' '}
                {maskEmail(email)}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ControlledTextField
                  labelColor="black"
                  control={control}
                  name="confirmationCode"
                  label="Code"
                  error={errors}
                  dark
                  placeholder="Enter code"
                />
                <Box sx={buttonsContainer}>
                  <Button
                    onClick={() => setVerificationState(false)}
                    sx={backButtonStyles}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    sx={
                      confirmationCode ? confirmButtonStyles : backButtonStyles
                    }
                  >
                    {!isLoading ? (
                      'Confirm'
                    ) : (
                      <Progress color="inherit" size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
