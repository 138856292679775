import { Box, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { MinerChart } from 'components/Charts/MinerChart';
import { SectionHeading } from 'components/SectionHeading';
import { DistributionPerCountry } from 'components/DistributionPerCountry';
import { itemHeaderStyles } from 'pages/Customer/Operation/Miners/styles';
import countryCodes from 'shared/constants/country-codes.json';
import {
  headingStyles,
  wrapperStyles,
  infoCardStyles,
  infoCardContentStyles,
} from './styles';

export const Miners = (props: any) => {
  const { machineByLocation, machinePerTypeData } = props;

  const location = (country: any) => {
    switch (country) {
      case 'AE':
        return 'UAE';
      default:
        return countryCodes.countries.find((c: any) => c.alpha2 === country)
          ?.country;
    }
  };

  return (
    <>
      <SectionHeading sx={headingStyles} name="Miners distribution" />
      <Box sx={wrapperStyles}>
        {machinePerTypeData && (
          <InfoCard
            showDivider={false}
            showTime={false}
            fullHeight
            title=""
            styles={infoCardStyles}
            hasAdditionalSpace={false}
            content={
              <Box sx={infoCardContentStyles}>
                <Typography sx={itemHeaderStyles}>
                  Distribution per miner type
                </Typography>
                <MinerChart items={machinePerTypeData} />
              </Box>
            }
          />
        )}
        {machineByLocation && (
          <InfoCard
            showDivider={false}
            showTime={false}
            fullHeight
            title=""
            styles={infoCardStyles}
            hasAdditionalSpace={false}
            content={
              <Box sx={infoCardContentStyles}>
                <Typography sx={itemHeaderStyles}>
                  Distribution per country
                </Typography>
                <DistributionPerCountry
                  items={machineByLocation}
                  getCountry={location}
                />
              </Box>
            }
          />
        )}
      </Box>
    </>
  );
};
