import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { ExpandableRow } from 'components/Tables/RedesignedTable/components/ExpandableRow/ExpandableRow';
import { IconButtonStyled } from 'components/Tables/RedesignedTable/components/RedesignedTableRow/styles';
import { useState } from 'react';
import { Box, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { TableActions } from 'components/TableActions';
import { colors } from 'shared/constants/theme';

type Props = {
  row: Record<string, any>;
  columns?: ColumnsType[];
  expandColumns?: ColumnsType[];
  expandPropertyName?: string;
  index?: number;
  showEnd?: boolean;
  onRowDelete?: any;
  isEditable?: boolean;
  handleEdit?: any;
  rowData?: any;
  readOnlyWithActions?: any;
};
export const RedesignedTableRow = ({
  index = 0,
  row,
  columns,
  expandColumns,
  expandPropertyName,
  showEnd,
  onRowDelete,
  handleEdit,
  rowData,
  readOnlyWithActions,
}: Props) => {
  const [expandableOpen, setExpandableOpen] = useState(false);
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          backgroundColor:
            (index % 2 ? colors.tableGrey : 'transparent') + ' !important',
          '& .MuiButton-contained': {
            backgroundColor: 'transparent',
            color: colors.grey,
            border: `1px solid ${colors.grey}`,
            boxShadow: 'none',
          },
        }}
      >
        {columns
          ? columns.map((column: any) => (
              <TableCell key={column.id} align="left">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {column.expandableIcon &&
                    (row.rowChildren ||
                      (expandColumns &&
                        expandPropertyName &&
                        row[expandPropertyName].length > 0)) && (
                      <IconButtonStyled
                        aria-label="expand row"
                        size="small"
                        onClick={() => setExpandableOpen((prev) => !prev)}
                      >
                        {expandableOpen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButtonStyled>
                    )}
                  {column.render
                    ? column.render(row)
                    : row[column.id] !== undefined || row[column.id] !== null
                      ? row[column.id]
                      : '-'}
                </Box>
              </TableCell>
            ))
          : null}
        {showEnd && (
          <TableActions
            handleEdit={handleEdit}
            row={row}
            rowData={rowData}
            onRowDelete={onRowDelete}
          />
        )}
      </TableRow>
      {expandableOpen &&
        (row.rowChildren ||
          (expandColumns &&
            expandPropertyName &&
            row[expandPropertyName]?.length > 0)) && (
          <ExpandableRow
            parentRow={row}
            open={expandableOpen}
            columns={expandColumns}
            items={expandPropertyName ? row[expandPropertyName] : ''}
          />
        )}
    </>
  );
};
