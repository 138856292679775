import { colors } from 'shared/constants/theme';

export const subtitleTextStyle = {
  fontSize: {
    xl: 16,
    lg: 16,
    md: 16,
    sm: 16,
    xs: 16,
  },
  fontWeight: 500,
};

export const hashrateChartOption = {
  fontSize: {
    xl: 16,
    lg: 16,
    md: 16,
    sm: 16,
    xs: 16,
  },
  ':hover': {
    cursor: 'pointer',
  },
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const hashrateChartOptionSelected = {
  fontSize: {
    xl: 16,
    lg: 16,
    md: 16,
    sm: 16,
    xs: 16,
  },
  background: colors.lightGreen,
  padding: '8px 16px',
  borderRadius: '10px',
  ':hover': {
    cursor: 'pointer',
  },
};
