import { colors } from 'shared/constants/theme';

export const headingStyles = {
  color: colors.white,
};

export const contentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '60px',
  marginTop: '20px',
};

export const coinStyles = (isBlack: boolean) => ({
  color: isBlack ? colors.black : colors.white,
  fontSize: {
    xs: '10px',
    sm: '10px',
    md: '14px',
    lg: '14px',
    xl: '14px',
  },
});
