import { Box, Typography, Button } from '@mui/material';
import UsersService from 'shared/services/users.service';
import { CustomModal } from 'components/Modals/CustomModal';
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  confirmButtonStyles,
  selectedDateStyles,
  modalContainerStyles,
} from './styles';
import { useNotification } from 'shared/hooks/useNotification';
import { Progress } from 'components/Progress';
import { useAuth } from 'shared/hooks/useAuth';
import moment from 'moment';

export const DatePickerModal = (props: any) => {
  const { open, setOpen, dates, subject } = props;
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useNotification();
  const { getCurrentUser } = useAuth();

  const handleModalClose = () => {
    setOpen(false);
    setSelectedDate(null);
    setSelectedTime(null);
  };

  const validDates = useMemo(() => {
    const uniqueDateStrings: string[] = Array.from(
      new Set(
        dates.map((item: any) => moment(item.start).format('YYYY-MM-DD')),
      ),
    );

    return uniqueDateStrings.map((dateStr) =>
      moment(dateStr, 'YYYY-MM-DD').startOf('day').toDate(),
    );
  }, [dates]);

  const validTimesMap = useMemo(() => {
    const map = new Map<string, moment.Moment[]>();

    dates.forEach((item: any) => {
      const time = moment(item.start);
      const dateKey = time.format('YYYY-MM-DD');

      if (!map.has(dateKey)) {
        map.set(dateKey, []);
      }

      map.get(dateKey)?.push(time);
    });

    return map;
  }, [dates]);

  const validTimes = useMemo(() => {
    if (!selectedDate) return [];

    const dateKey = selectedDate.format('YYYY-MM-DD');
    return (validTimesMap.get(dateKey) || []).map((time) =>
      moment(selectedDate)
        .set({
          hour: time.hour(),
          minute: time.minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
    );
  }, [selectedDate, validTimesMap]);

  useEffect(() => {
    if (!selectedDate && validDates.length > 0) {
      const firstDate = moment(validDates[0]);
      setSelectedDate(firstDate);
    }
  }, [validDates, selectedDate]);

  const handleCalendarBook = async (): Promise<void> => {
    if (selectedDate && selectedTime) {
      setLoading(true);
      const data = {
        date: selectedTime.toISOString(),
        subject,
      };

      try {
        await UsersService.setUserCalendarSlot(data);
        showSnackbar('Successfully booked the meeting', 'success');
        await getCurrentUser();
      } catch (err: any) {
        showSnackbar(err.error, 'error');
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  return (
    <CustomModal open={open} onClose={handleModalClose}>
      <Box sx={modalContainerStyles}>
        <DatePicker
          selected={(selectedTime || selectedDate)?.toDate()}
          onChange={(date) => {
            if (date instanceof Date) {
              const mDate = moment(date);
              const dateKey = mDate.format('YYYY-MM-DD');
              const availableTimes = validTimesMap.get(dateKey) || [];

              setSelectedDate(mDate);

              if (availableTimes.some((time) => time.hour() === mDate.hour())) {
                setSelectedTime(mDate);
              } else {
                setSelectedTime(null);
              }
            }
          }}
          includeDates={validDates}
          showTimeSelect
          timeIntervals={60}
          includeTimes={validTimes}
          inline
        />

        {selectedDate && selectedTime && (
          <Typography sx={selectedDateStyles}>
            Selected: {selectedDate.format('L')} {selectedTime.format('HH:mm')}{' '}
            - {selectedTime.clone().add(1, 'hour').format('HH:mm')}
          </Typography>
        )}

        <Button
          sx={confirmButtonStyles}
          disabled={!selectedDate || !selectedTime}
          onClick={handleCalendarBook}
        >
          {!loading ? 'Confirm' : <Progress color="inherit" size={20} />}
        </Button>
      </Box>
    </CustomModal>
  );
};
