import { colors } from 'shared/constants/theme';

export const cardContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const totalEarningsStyles = {
  fontSize: '20px',
  color: colors.white,
  fontWeight: '500',
};

export const totalEarningsValueStyles = {
  fontSize: '32px',
  marginTop: '5px',
  fontWeight: '600',
  lineHeight: '56px',
  color: colors.white,
};
