import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomersDetails } from 'components/CustomerDetails';
import { Upfront } from 'components/CustomerDetails/types';
import { coins } from 'shared/utils/currency';
import { useAuth } from 'shared/hooks/useAuth';
import { useFetch } from 'shared/hooks/useFetch';
import { useNotification } from 'shared/hooks/useNotification';
import ContractsService from 'shared/services/contracts.service';
import dashboardService from 'shared/services/dashboard.service';
import UsersService from 'shared/services/users.service';

const AdminCustomersDetails = () => {
  const location = useLocation();
  const { showSnackbar } = useNotification();
  const { customerId } = useParams();
  const { user } = useAuth();

  const [selectedCurrency, setSelectedCurrency] = useState(coins[0]);
  const [upfront, setUpfront] = useState<Upfront | null | undefined>(null);

  const [customerData] = useFetch(() => UsersService.getUser(customerId));
  const [ownerData] = useFetch(() => {
    if (customerData && customerData.ownerId)
      return UsersService.getUser(customerData.ownerId);
  });

  const [hashrate] = useFetch(
    () =>
      dashboardService.getDashboardHashrateChart({
        customerId,
        currency: selectedCurrency.currency,
      }),
    [selectedCurrency],
  );

  const fetchUpfront = async () => {
    setUpfront(null);
    try {
      const response = await ContractsService.getCompanyProfit({
        customerId,
        currency: selectedCurrency.currency,
      });

      setUpfront(response);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    fetchUpfront();
  }, [selectedCurrency]);

  return (
    <CustomersDetails
      customer={customerData}
      owner={ownerData.user}
      hashrateData={hashrate.hashrateData}
      upfront={upfront}
      role={user.role}
      backNavigation={location.state.prevPath}
      coins={coins}
      selectedCurrency={selectedCurrency}
      setSelectedCurrency={setSelectedCurrency}
    />
  );
};

export default AdminCustomersDetails;
