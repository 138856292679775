import { Box, Button, Typography } from '@mui/material';
import {
  cardTitleContainer,
  currencyAbbStyles,
  currencyNameStyles,
  cardDescription,
  innerCardStyles,
  cardContainer,
  sectionTitle,
  sectionValue,
  sectionContainer,
  bookButtonStyles,
} from '../styles';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { DatePickerModal } from 'components/CampaignProspects/DatePickerModal';
import { useState } from 'react';
import { dollarYieldDescriptions } from 'shared/constants/dollar-yield';

export const DollarYieldCard = (props: any) => {
  const { simulation, coin, dates } = props;
  const { coinName, coinAbb, logo, currency } = coin;
  const { totalInvestment, roi, annualReturn } = simulation;
  const [open, setOpen] = useState(false);

  const descriptions = dollarYieldDescriptions as Record<string, string>;

  return (
    <Box sx={cardContainer}>
      <Box sx={cardTitleContainer}>
        <img src={logo} alt={coinName} width={48} height={48} />
        <Box>
          <Typography sx={currencyNameStyles}>{coinName}</Typography>
          <Typography sx={currencyAbbStyles}>{coinAbb}</Typography>
        </Box>
      </Box>
      <Typography sx={cardDescription}>
        {descriptions[simulation.currency]}
      </Typography>
      <Box sx={innerCardStyles}>
        <Box sx={sectionContainer}>
          <Typography sx={sectionTitle}>Example:</Typography>
          <Typography sx={sectionValue}>
            ${formatNumberToLocale(totalInvestment, 0)} Investment
          </Typography>
        </Box>
        <Box sx={sectionContainer}>
          <Typography sx={sectionTitle}>Projected ROI:</Typography>
          <Typography sx={sectionValue}>
            {roi.toFixed(2)}% in USD (${formatNumberToLocale(annualReturn, 2)}{' '}
            annual return)
          </Typography>
        </Box>
      </Box>
      <Button sx={bookButtonStyles} onClick={() => setOpen(!open)}>
        Book a Meeting
      </Button>
      <DatePickerModal
        open={open}
        setOpen={setOpen}
        dates={dates}
        subject={currency}
      />
    </Box>
  );
};
