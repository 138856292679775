import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Active } from 'shared/utils/enum/activeEnums';
import { Skeleton } from '@mui/material';
import {
  createDataForHashrateMeasuredChart,
  createOptionsForHashrateMeasuredChart,
} from 'shared/utils/createData';
import { linePlugin } from '../plugins';

export const HashrateChart = (props: any) => {
  const { hashrateData, activeChart } = props;

  const hashrateMeasuredData = useMemo(() => {
    const speedList =
      hashrateData?.[activeChart === Active.DAY ? 'dailyData' : 'hourlyData'] ||
      [];

    const unit = speedList.some((el: any) => el.speed < 0.001)
      ? 'TH/s'
      : 'PH/s';

    return {
      hashrateArray: createDataForHashrateMeasuredChart(speedList, unit),
      unit,
    };
  }, [hashrateData, activeChart]);

  if (!hashrateData) {
    return <Skeleton variant="rectangular" height="100%" />;
  }

  return (
    <Line
      options={{
        ...createOptionsForHashrateMeasuredChart(
          hashrateMeasuredData.hashrateArray,
          activeChart,
          hashrateMeasuredData.unit,
        ),
        interaction: {
          mode: 'index',
          intersect: false,
        },
      }}
      data={hashrateMeasuredData.hashrateArray}
      plugins={[linePlugin]}
    />
  );
};
