import { colors } from 'shared/constants/theme';

export const linePlugin = {
  id: 'dashedLine',
  afterDraw: function (chart: any) {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([10, 10]);

      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = colors.lightGreen;
      ctx.stroke();
      ctx.restore();
    }
  },
};
