import { Box } from '@mui/material';
import { HearstLogo } from 'components/Icons/HearstLogo';
import { userRedirectionMapping } from 'routes/mappings';
import { useAuth } from 'shared/hooks/useAuth';
import { Link, useLocation } from 'react-router-dom';

export const LogoLink = () => {
  const { user } = useAuth();
  const location = useLocation();
  const targetPath = userRedirectionMapping[user.role];

  const handleLogoClick = (event: any) => {
    if (location.pathname === targetPath) {
      event.preventDefault();
      window.location.reload();
    }
  };

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Link to={targetPath} onClick={handleLogoClick}>
        <HearstLogo />
      </Link>
    </Box>
  );
};
