import HttpService from './http.service';

class AuthService {
  endpoint: string = 'auth';

  login = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/login`, payload);

  signUp = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/sign-up`, payload);

  prospectSignUp = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/prospect-sign-up`, payload);

  changePassword = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/change-password`, payload);

  forgotPassword = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/forgot-password`, payload);

  confirmEmailCode = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/confirm-email-code`, payload);

  resetPassword = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/reset-password`, payload);
}

export default new AuthService();
