import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Statistics } from 'components/Statistics';
import { SectionHeading } from 'components/SectionHeading';
import { LiveUtcDateTime } from 'components/LiveUtcDateTime';
import { DashboardStatistics } from 'shared/types/dashboard';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { headingStyles, headingWrapperStyles } from './styles';

type Props = {
  statistics?: DashboardStatistics;
};

export const Operations = ({ statistics }: Props) => {
  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Investments',
        value: statistics
          ? `$${formatNumberToLocale(statistics.totalInvestment, 2)}`
          : null,
        currency: 'USD',
      },
      {
        title: 'Total Bitcoin hashrate',
        value: statistics ? statistics.bitcoinHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
      {
        title: 'Total Kaspa hashrate',
        value: statistics ? statistics.kaspaHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
    ],
    [statistics],
  );

  return (
    <>
      <Box sx={headingWrapperStyles}>
        <SectionHeading sx={headingStyles} name="Your operations" />
        <LiveUtcDateTime />
      </Box>
      <Statistics statistics={statisticsData} />
    </>
  );
};
