export const authButtonStyles = {
  borderRadius: '12px',
  fontSize: '16px',
  height: {
    xl: '56px',
    lg: '56px',
    md: '56px',
    sm: '56px',
    xs: '44px',
  },
};
