import { useCallback, useEffect, useState } from 'react';
import { getColumns } from './columns';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { SubAccountForm } from 'components/Forms/UserForms/SubAccountForm';
import { Layout } from 'components/Layout';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';
import { RedesignedTable } from 'components/Tables/RedesignedTable';

const SubAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userData, setUserData] = useState<any[] | null>(null);
  const [subAccountDate, setSubAccountData] = useState<any[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [delUserID, setDelUserID] = useState<string>('');
  const { showSnackbar } = useNotification();
  const fetchAccount = async () => {
    setSubAccountData(null);
    try {
      const response = await UsersService.getSubAccounts({
        pageNumber: currentPage,
        limit: rowsPerPage,
      });

      const { users, totalPages } = response;
      setSubAccountData(users);
      setTotalPages(totalPages);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage, rowsPerPage]);

  const handleDeleteUser = useCallback(async () => {
    if (delUserID) {
      await UsersService.deleteUser(delUserID);
      fetchAccount();
      setDelUserID('');
      showSnackbar('Successfully Deleted', 'success');
    }
  }, [delUserID]);

  const columns = getColumns({
    handleEdit,
    onRowDelete: setDelUserID,
  });

  return (
    <Layout>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <SubAccountForm setShowModal={setShowModal} onFinish={fetchAccount} />
      </CustomModal>
      <RedesignedTable
        tableName="Sub Accounts"
        items={subAccountDate}
        columns={columns}
        setShowModal={setShowModal}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
      />
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <SubAccountForm
          setShowModal={setShowEditModal}
          editData={userData}
          onFinish={fetchAccount}
        />
      </CustomModal>
      <CustomModal open={!!delUserID} onClose={() => setDelUserID('')}>
        <Box className="container">
          <DeleteModal
            title="Sub account"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserID('')}
          />
        </Box>
      </CustomModal>
    </Layout>
  );
};

export default SubAccount;
