import { colors } from 'shared/constants/theme';

const buttonHeight = {
  xl: '56px',
  lg: '56px',
  md: '56px',
  sm: '56px',
  xs: '44px',
};

export const styledBackground = {
  background:
    'linear-gradient(117deg, rgba(4,40,20,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(4,40,20,1) 100%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: {
    xl: 'unset',
    lg: 'unset',
    md: 'unset',
    sm: 'unset',
    xs: 'center',
  },
  alignItems: 'center',
  gap: '40px',
  overflow: 'hidden',
  minWidth: '100%',
  minHeight: '100%',
  overflowY: 'hidden',
  borderRadius: '0px',
  padding: {
    xl: '40px',
    lg: '40px',
    md: '40px',
    sm: '40px',
    xs: '20px',
  },
};

export const logoContainer = {
  display: {
    xl: 'none',
    lg: 'none',
    md: 'none',
    sm: 'none',
    xs: 'flex',
  },
  width: '100%',
  flexDirection: 'flex-start',
};

export const sectionContainerStyles = {
  border: {
    xl: `1px solid ${colors.darkGrey}`,
    lg: `1px solid ${colors.darkGrey}`,
    md: `1px solid ${colors.darkGrey}`,
    sm: `1px solid ${colors.darkGrey}`,
    xs: 'none',
  },
  borderRadius: '16px',
  padding: {
    xl: '32px',
    lg: '32px',
    md: '32px',
    sm: '32px',
    xs: '0px',
  },
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '32px',
  marginTop: {
    xl: '0px',
    lg: '0px',
    md: '0px',
    sm: '0px',
    xs: '150px',
  },
};

export const textContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const textStyles = {
  fontSize: {
    xl: `32px`,
    lg: `32px`,
    md: `32px`,
    sm: `32px`,
    xs: '28px',
  },
  fontWeight: 500,
  color: colors.lightGrey,
  textAlign: 'center',
};

export const startButtonStyles = {
  fontSize: '16px',
  fontWeight: 500,
  width: '100%',
  color: colors.lightGrey,
  backgroundColor: colors.lightGreen,
  height: buttonHeight,
  borderRadius: '6px',
  marginTop: {
    xl: '0px',
    lg: '0px',
    md: '0px',
    sm: '0px',
    xs: '150px',
  },
};

export const emailTextContainerStyles = {
  border: {
    xl: `1px solid ${colors.darkGrey}`,
    lg: `1px solid ${colors.darkGrey}`,
    md: `1px solid ${colors.darkGrey}`,
    sm: `1px solid ${colors.darkGrey}`,
    xs: 'none',
  },
  borderRadius: '16px',
  padding: {
    xl: '32px',
    lg: '32px',
    md: '32px',
    sm: '32px',
    xs: '0',
  },
  height: {
    xl: '100%',
    lg: '100%',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
  display: { xs: 'flex' },
  flexDirection: { xs: 'column' },
  justifyContent: {
    xl: 'unset',
    lg: 'unset',
    md: 'unset',
    sm: 'unset',
    xs: 'space-between',
  },
};

export const contentContainer = {
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const emailTitleTextStyles = {
  fontWeight: 500,
  fontSize: '32px',
  color: colors.lightGrey,
  marginBottom: '6px',
};

export const emailTextStyles = {
  fontWeight: 400,
  fontSize: '16px',
  color: colors.lightGrey,
  marginBottom: '24px',
};

export const buttonsContainer = {
  display: { xs: 'flex' },
  flexDirection: {
    xl: `row`,
    lg: `row`,
    md: `row`,
    sm: `row`,
    xs: 'column',
  },
  columnGap: '16px',
  marginTop: '42px',
  rowGap: '20px',
};

export const backButtonStyles = {
  width: {
    xl: `50%`,
    lg: `50%`,
    md: `50%`,
    sm: `50%`,
    xs: '100%',
  },
  fontSize: '16px',
  fontWeight: 500,
  color: colors.lightGrey,
  backgroundColor: colors.darkGrey,
  height: buttonHeight,
  borderRadius: '6px',
  '&:hover': {
    color: colors.lightGrey,
    backgroundColor: colors.darkGrey,
  },
};

export const confirmButtonStyles = {
  width: {
    xl: `50%`,
    lg: `50%`,
    md: `50%`,
    sm: `50%`,
    xs: '100%',
  },
  fontSize: '16px',
  fontWeight: 500,
  color: colors.lightGrey,
  backgroundColor: colors.lightGreen,
  height: buttonHeight,
  borderRadius: '6px',
};
