import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SimulationService from 'shared/services/simulation.service';
import {
  descriptionStyles,
  titleStyles,
  cardsContainer,
  progressStyles,
  progressContainerStyles,
} from './styles';
import { coins } from 'shared/utils/currency';
import { DollarYieldCard } from './DollarYieldCard';
import { Progress } from 'components/Progress';
import { useNotification } from 'shared/hooks/useNotification';

export const DollarYield = (props: any) => {
  const { dates } = props;
  const [simulations, setSimulations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useNotification();

  const fetchDollarYields = async () => {
    setSimulations([]);
    try {
      setLoading(true);
      const data = await SimulationService.getDollarYieldSimulations();

      setSimulations(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDollarYields();
  }, []);

  const getCoin = (currency: any) => {
    return coins.find((coin) => coin.currency === currency);
  };

  return (
    <Box>
      <Box>
        <Typography sx={titleStyles}>Mining Opportunities</Typography>
        <Typography sx={descriptionStyles}>
          Explore all the cryptocurrency mining opportunities offered by Hearst.
          Each opportunity displays the expected annual ROI based on a specific
          investment amount.
        </Typography>
      </Box>

      {loading ? (
        <Box sx={progressContainerStyles}>
          <Progress sx={progressStyles} size={30} />
        </Box>
      ) : (
        simulations.length > 0 && (
          <Box sx={cardsContainer}>
            {simulations.map((simulation, index) => (
              <DollarYieldCard
                dates={dates}
                key={index}
                simulation={simulation}
                coin={getCoin(simulation.currency)}
              />
            ))}
          </Box>
        )
      )}
    </Box>
  );
};
