import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { colors } from 'shared/constants/theme';
import { handleDifficultyChartPoint } from 'shared/utils/currency';
import { defaultLineChartStyles, setLinearGradientColor } from '../styles';
import { linePlugin } from '../plugins';

export const ChainChart = ({
  items = [],
  type,
  currency,
}: any): JSX.Element => {
  const data = useMemo(
    () => ({
      labels: items?.map((elem: any) => elem.date),
      datasets: [
        {
          data: items?.map((elem: any) => elem[type]) ?? [],
          borderColor: colors.lightGreen,
          fill: true,
          pointBackgroundColor: colors.lightGreen,
          pointRadius: 0,
          borderWidth: 1.5,
          backgroundColor: (context: any) => setLinearGradientColor(context),
        },
      ],
    }),
    [type, items],
  );

  const descriptor = useMemo(() => {
    const hashrateMeasurement = handleDifficultyChartPoint(currency);

    return type === 'revenue' ? '$' : hashrateMeasurement;
  }, [type, currency]);

  const name = useMemo(
    () => `${type[0].toUpperCase()}${type.substring(1)}`,
    [type],
  );

  if (!items.length) {
    return <Skeleton height={230} variant="rectangular" />;
  }

  return (
    <Line
      options={{
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: defaultLineChartStyles.elements,
        scales: {
          y: {
            ticks: {
              callback: (value: any) => {
                return `${value.toLocaleString('en-US')} ${descriptor}`;
              },
            },
          },
          x: {
            ticks: {
              callback(tickValue) {
                return moment(data.labels[tickValue]).format('MMM DD');
              },
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 7,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: (item) => {
                const label = item[0].label;
                return moment(label).format('ddd, MMM DD');
              },

              label: (item: any) =>
                `${name} ${item.formattedValue} ${descriptor}`,
            },
            ...defaultLineChartStyles.plugins.tooltip,
          },
        },
      }}
      data={data}
      plugins={[linePlugin]}
    />
  );
};
